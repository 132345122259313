import * as React from "react";
import {
  TextField,
  Box,
  Typography,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useFormik } from "formik";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDispatch, useSelector } from "react-redux";
import { SetCommentId } from "store/slices/modalsSlice";
import SendIcon from "@mui/icons-material/Send";
import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import { postComments, rankDownReq } from "api/Comments";
import { commentSchema } from "var/Schema";

const dialogStyle = {
  "& .MuiDialog-paper": {
    width: { xs: "270px", sm: "389px" },
    maxHeight: { xs: "210px", sm: "210px", md: "230px" },
    background: "#FAFAFA",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    boxRadius: "none",
    borderRadius: "8px",
    padding: "0px 10px",
  },
  "& .MuiDialog-container": {
    background: "rgba(255, 255, 255, 0.02)",
    backdropFilter: "blur(10px)",
  },
};

const dialogHeader = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: { xs: "20px 20px 20px 20px", sm: "20px 20px 20px 40px" },
};

export default function CommentsModal({
  openModal,
  setOpenModal,
  handleCommentCount,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const id = useSelector((state) => state.modals.commentsId);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  // rank down mutation
  const { mutate: rankDownMutate } = useMutation(rankDownReq, {
    onSuccess: () => {
      handleCommentCount();
      handleClose();
    },
    onError: (error) => {
      queryClient.invalidateQueries("getAllListing");
      toast.error(error?.response?.data?.message || "Something went wrong!");
    },
  });

  //   Define a mutation for comments

  const { mutate, isLoading } = useMutation(postComments, {
    onSuccess: (data) => {
      rankDownMutate(id);
    },
    onError: (error) => {
      dispatch(SetCommentId(null));
      handleClose();
      toast.error(error?.response?.data?.message || "Something went wrong!");
    },
  });

  // Formik object for managing form state and validation
  const formik = useFormik({
    initialValues: {
      comment: "",
    },
    validationSchema: commentSchema,
    onSubmit: (values) => {
      mutate({
        id: id,
        data: {
          body: values.comment,
        },
      });
    },
  });

  const {
    values,
    handleBlur,
    handleSubmit,
    handleChange,
    touched,
    errors,
    resetForm,
  } = formik;

  const handleClose = () => {
    dispatch(SetCommentId(null));
    setOpenModal(false);
    resetForm();
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={openModal}
        sx={dialogStyle}
        onClose={handleClose}
      >
        <Box sx={dialogHeader}>
          <CancelIcon
            sx={{ fontSize: "20px", cursor: "pointer" }}
            onClick={handleClose}
          />
        </Box>

        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: "13px", sm: "15px" },
            mb: 1,
          }}
        >
          To downvote, please provide a comment explaining your reason.
        </Typography>

        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              maxWidth: "100%",
              margin: "auto",
              padding: "20px 0px",
              textAlign: "center",
            }}
          >
            <TextField
              name="comment"
              placeholder="Enter Comment"
              fullWidth
              value={values.comment}
              onBlur={handleBlur}
              onChange={handleChange}
              sx={{
                "& .MuiOutlinedInput-root .MuiOutlinedInput-input": {
                  padding: "0px 0px 0px 15px  !important",
                  textAlign: "left",
                  borderRadius: "20px 0px 0px 20px !important",
                  fontSize: "13px",
                  height: "36px",
                },
              }}
              autoComplete="new-password"
              error={touched.comment && Boolean(errors.comment)}
              helperText={
                touched.comment &&
                Boolean(errors.comment) &&
                touched.comment &&
                errors.comment
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {isLoading ? (
                      <CircularProgress size={12} />
                    ) : (
                      <SendIcon
                        disabled={isLoading}
                        sx={{ fontSize: "1.2rem", cursor: "pointer" }}
                        onClick={handleSubmit}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </form>
      </Dialog>
    </React.Fragment>
  );
}

import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { infoIcon } from "assets/images";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const infoBox = {
  height: "100%",
  background: "rgb(131 190 255 / 12%)",
  borderRadius: "8px",
  padding: "8px 16px",
};

const infoBox2 = {
  height: "35px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  margin: "0px 16px",
  borderBottom: "1px solid #eeeeee",
};

const Info = ({ data }) => {
  const [open, setOpen] = useState(false);

  const handleSeeMore = (e) => {
    e.preventDefault();
    setOpen(!open);
  };
  return (
    <Box sx={{ maxWidth: "960px", margin: "auto", pb: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={12}>
          <Box sx={infoBox}>
            <Typography
              variant="h2"
              sx={{ textAlign: "left", fontSize: "18px", mb: 0.5 }}
            >
              Details
            </Typography>

            <Box sx={infoBox2}>
              <Typography variant="subtitle5">Industry</Typography>
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                <Typography variant="subtitle5" sx={{ mr: 1 }}>
                  {data?.industry || "--"}
                </Typography>
                <img src={infoIcon} alt="info Icon" />
              </Box>
            </Box>
            <Box sx={infoBox2}>
              <Typography variant="subtitle5">Catagory</Typography>
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                <Typography variant="subtitle5" sx={{ mr: 1 }}>
                  {data?.sub_industry || "--"}
                </Typography>
                <img src={infoIcon} alt="info Icon" />
              </Box>
            </Box>
            <Box sx={infoBox2}>
              <Typography variant="subtitle5">State</Typography>
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                <Typography variant="subtitle5" sx={{ mr: 1 }}>
                  {data?.state || "--"}
                </Typography>
                <img src={infoIcon} alt="info Icon" />
              </Box>
            </Box>
            <Box sx={infoBox2}>
              <Typography variant="subtitle5">City</Typography>
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                <Typography variant="subtitle5" sx={{ mr: 1 }}>
                  {data?.city || "--"}
                </Typography>
                <img src={infoIcon} alt="info Icon" />
              </Box>
            </Box>

            {open && (
              <>
                <Box sx={infoBox2}>
                  <Typography variant="subtitle5">Established</Typography>
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    <Typography variant="subtitle5" sx={{ mr: 1 }}>
                      {data?.established || "--"}
                    </Typography>
                    <img src={infoIcon} alt="info Icon" />
                  </Box>
                </Box>
                <Box sx={infoBox2}>
                  <Typography variant="subtitle5">Timeframe</Typography>
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    <Typography variant="subtitle5" sx={{ mr: 1 }}>
                      {data?.timeframe || "--"}
                    </Typography>
                    <img src={infoIcon} alt="info Icon" />
                  </Box>
                </Box>
                <Box sx={infoBox2}>
                  <Typography variant="subtitle5">Lifestyle</Typography>
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    <Typography variant="subtitle5" sx={{ mr: 1 }}>
                      {data?.lifestyle || "--"}
                    </Typography>
                    <img src={infoIcon} alt="info Icon" />
                  </Box>
                </Box>
                <Box sx={infoBox2}>
                  <Typography variant="subtitle5">Reason</Typography>
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    <Typography variant="subtitle5" sx={{ mr: 1 }}>
                      {data?.reason || "--"}
                    </Typography>
                    <img src={infoIcon} alt="info Icon" />
                  </Box>
                </Box>
                <Box sx={infoBox2}>
                  <Typography variant="subtitle5">Asking price</Typography>
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    <Typography variant="subtitle5" sx={{ mr: 1 }}>
                      $ {data?.asking_price?.toLocaleString() || "--"}
                    </Typography>
                    <img src={infoIcon} alt="info Icon" />
                  </Box>
                </Box>
                <Box sx={infoBox2}>
                  <Typography variant="subtitle5">Gross revenue</Typography>
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    <Typography variant="subtitle5" sx={{ mr: 1 }}>
                      $ {data?.gross_revenue?.toLocaleString() || "--"}
                    </Typography>
                    <img src={infoIcon} alt="info Icon" />
                  </Box>
                </Box>
                <Box sx={infoBox2}>
                  <Typography variant="subtitle5">Cash Flow</Typography>
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    <Typography variant="subtitle5" sx={{ mr: 1 }}>
                      $ {data?.cash_flow?.toLocaleString() || "--"}
                    </Typography>
                    <img src={infoIcon} alt="info Icon" />
                  </Box>
                </Box>
                <Box sx={infoBox2}>
                  <Typography variant="subtitle5">EBITDA</Typography>
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    <Typography variant="subtitle5" sx={{ mr: 1 }}>
                      $ {data?.ebitda_value?.toLocaleString() || "--"}
                    </Typography>
                    <img src={infoIcon} alt="info Icon" />
                  </Box>
                </Box>
                <Box sx={infoBox2}>
                  <Typography variant="subtitle5">Real Estate</Typography>
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    <Typography variant="subtitle5" sx={{ mr: 1 }}>
                      $ {data?.total_rent_with_utilities?.toLocaleString() || "--"}
                    </Typography>
                    <img src={infoIcon} alt="info Icon" />
                  </Box>
                </Box>
                <Box sx={infoBox2}>
                  <Typography variant="subtitle5">Equipment</Typography>
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    <Typography variant="subtitle5" sx={{ mr: 1 }}>
                      $ {data?.equipment_amount?.toLocaleString() || "--"}
                    </Typography>
                    <img src={infoIcon} alt="info Icon" />
                  </Box>
                </Box>
                <Box sx={infoBox2}>
                  <Typography variant="subtitle5">Inventory</Typography>
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    <Typography variant="subtitle5" sx={{ mr: 1 }}>
                      $ {data?.inventory_amount?.toLocaleString() || "--"}
                    </Typography>
                    <img src={infoIcon} alt="info Icon" />
                  </Box>
                </Box>
                <Box sx={infoBox2}>
                  <Typography variant="subtitle5">
                    Operating expenses
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    <Typography variant="subtitle5" sx={{ mr: 1 }}>
                      $ {data?.operating_expenses?.toLocaleString() || "--"}
                    </Typography>
                    <img src={infoIcon} alt="info Icon" />
                  </Box>
                </Box>
                <Box sx={infoBox2}>
                  <Typography variant="subtitle5">Employees</Typography>
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    <Typography variant="subtitle5" sx={{ mr: 1 }}>
                      $ {data?.employees_expense?.toLocaleString() || "--"}
                    </Typography>
                    <img src={infoIcon} alt="info Icon" />
                  </Box>
                </Box>
                <Box sx={infoBox2}>
                  <Typography variant="subtitle5">Website</Typography>
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    <Typography variant="subtitle5" sx={{ mr: 1 }}>
                      {data?.website || "--"}
                    </Typography>
                    <img src={infoIcon} alt="info Icon" />
                  </Box>
                </Box>
              </>
            )}

            <Box
              onClick={handleSeeMore}
              sx={{
                pt: 1,
                cursor: "pointer",
                maxWidth: "80px",
                height: "15px",
                margin: "auto",
              }}
            >
              {open ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <KeyboardArrowUpIcon sx={{ mb: "-8px" }} />
                  <Typography variant="subtitle2">See less</Typography>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography variant="subtitle2">See more</Typography>
                  <KeyboardArrowDownIcon sx={{ mt: "-8px" }} />
                </Box>
              )}
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={6} sx={{ mt: 2 }}>
          <Box sx={infoBox}>
            <Typography
              variant="h2"
              sx={{ textAlign: "left", fontSize: "18px", mb: 0.5 }}
            >
              SWOT
            </Typography>
            <Typography
              variant="subtitle5"
              sx={{ textAlign: "left", fontWeight: 400 }}
              dangerouslySetInnerHTML={{ __html: data?.swot || "--" }}
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={6} sx={{ mt: 2 }}>
          <Box sx={infoBox}>
            <Typography
              variant="h2"
              sx={{ textAlign: "left", fontSize: "18px", mb: 0.5 }}
            >
              Marketing
            </Typography>
            <Typography
              variant="subtitle5"
              sx={{ textAlign: "left", fontWeight: 400 }}
              dangerouslySetInnerHTML={{ __html: data?.marketing || "--" }}
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={6} sx={{ mt: 2 }}>
          <Box sx={infoBox}>
            <Typography
              variant="h2"
              sx={{ textAlign: "left", fontSize: "18px", mb: 0.5 }}
            >
              Training / Transition Plan
            </Typography>
            <Typography
              variant="subtitle5"
              sx={{ textAlign: "left", fontWeight: 400 }}
              dangerouslySetInnerHTML={{ __html: data?.transition_plan || "--" }}
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={6} sx={{ mt: 2 }}>
          <Box sx={infoBox}>
            <Typography
              variant="h2"
              sx={{ textAlign: "left", fontSize: "18px", mb: 0.5 }}
            >
              Real Estate / Assets
            </Typography>
            <Typography
              variant="subtitle5"
              sx={{ textAlign: "left", fontWeight: 400 }}
              dangerouslySetInnerHTML={{ __html: data?.assets || "--" }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Info;

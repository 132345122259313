import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  login: false,
  signup: false,
  forgot: false,
  industoryModal: false,
  commentsId: null,
  delete: false,
};

// This slice can be used for all modals and accessed globally from anywhere in the application.

export const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    OpenLoginModal: (state, action) => {
      state.login = action.payload;
    },

    OpenSignupModal: (state, action) => {
      state.signup = action.payload;
    },

    OpenForgotModal: (state, action) => {
      state.forgot = action.payload;
    },

    OpenIndustoryModal: (state, action) => {
      state.industoryModal = action.payload;
    },

    SetCommentId: (state, action) => {
      state.commentsId = action.payload;
    },
    OpenDeleteModal: (state, action) => {
      state.delete = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  OpenLoginModal,
  OpenSignupModal,
  OpenIndustoryModal,
  SetCommentId,
  OpenDeleteModal,
  OpenForgotModal,
} = modalsSlice.actions;

export default modalsSlice.reducer;

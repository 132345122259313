import * as React from "react";
import {
  TextField,
  Box,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useFormik } from "formik";
import CancelIcon from "@mui/icons-material/Cancel";
import { BackIcon } from "assets/images";
import DialogActions from "@mui/material/DialogActions";
import { signinSchema } from "var/Schema";
import SignUp from "./SignUp";
import { useDispatch, useSelector } from "react-redux";
import {
  OpenForgotModal,
  OpenLoginModal,
  OpenSignupModal,
} from "store/slices/modalsSlice";
import { login } from "api/Auth";
import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import { userAuthenticate } from "store/slices/authSlice";
import ForgotPassword from "./ForgotPassword";

const dialogStyle = {
  "& .MuiDialog-paper": {
    width: { xs: "270px", sm: "389px" },
    maxHeight: { xs: "465px", sm: "465px", md: "465px" },
    minHeight: "420px",
    background: "#FAFAFA",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    boxRadius: "none",
    borderRadius: "8px",
  },
  "& .MuiDialog-container": {
    background: "rgba(255, 255, 255, 0.02)",
    backdropFilter: "blur(10px)",
  },
};

const dialogHeader = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: { xs: "20px 20px 20px 20px", sm: "20px 20px 20px 40px" },
};

export default function SignIn() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const open = useSelector((state) => state.modals.login);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  // Define a mutation for login

  const { mutate, isLoading } = useMutation(login, {
    onSuccess: (data) => {
      queryClient.removeQueries("getAllListing");
      dispatch(userAuthenticate(true));
      queryClient.invalidateQueries("getAllListing");
      toast.success("You have successfully logged in.");
      handleClose();
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message || "Something went wrong!");
    },
  });

  // Formik object for managing form state and validation

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: signinSchema,
    onSubmit: (values) => {
      mutate(values);
    },
  });

  const {
    values,
    handleBlur,
    handleSubmit,
    handleChange,
    touched,
    errors,
    resetForm,
  } = formik;

  const handleClose = () => {
    dispatch(OpenLoginModal(false));
    resetForm();
  };

  const handleSignUp = (e) => {
    e.preventDefault();
    dispatch(OpenSignupModal(true));
    handleClose();
  };

  const handleForgot = (e) => {
    e.preventDefault();
    dispatch(OpenForgotModal(true));
    handleClose();
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        sx={dialogStyle}
        onClose={handleClose}
      >
        <Box sx={dialogHeader}>
          <CancelIcon
            sx={{ fontSize: "20px", cursor: "pointer" }}
            onClick={handleClose}
          />
        </Box>

        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: "35px", sm: "49px" },
            letterSpacing: "-1.715px",
          }}
        >
          Login
        </Typography>
        <Typography variant="h2" sx={{ fontSize: { xs: "13px", sm: "15px" } }}>
          Welcome to the Dealranker community.
        </Typography>

        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              maxWidth: "300px",
              margin: "auto",
              padding: "30px 10px",
              textAlign: "center",
            }}
          >
            <TextField
              name="email"
              placeholder="Email"
              fullWidth
              value={values.email}
              onBlur={handleBlur}
              onChange={handleChange}
              sx={{
                mb: "10px",
                "& ::placeholder": {
                  textAlign: "center !important",
                  fontWeight: `${500} !important`,
                  color: `${theme.palette.primary.gray} !important`,
                  opacity: 1,
                },
              }}
              autoComplete="username"
              error={touched.email && Boolean(errors.email)}
              helperText={
                touched.email &&
                Boolean(errors.email) &&
                touched.email &&
                errors.email
              }
            />

            <TextField
              name="password"
              type="password"
              placeholder="Password"
              fullWidth
              value={values.password}
              onBlur={handleBlur}
              onChange={handleChange}
              sx={{
                mb: "10px",
                "& ::placeholder": {
                  textAlign: "center !important",
                  fontWeight: `${500} !important`,
                  color: `${theme.palette.primary.gray} !important`,
                  opacity: 1,
                },
              }}
              autoComplete="password"
              error={touched.password && Boolean(errors.password)}
              helperText={
                touched.password &&
                Boolean(errors.password) &&
                touched.password &&
                errors.password
              }
            />

            <DialogActions
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: 0,
                mt: 1,
              }}
            >
              <Button
                variant="contained"
                type="submit"
                disabled={isLoading}
                sx={{
                  maxWidth: "500px",
                  boxShadow: "none",
                  padding: "20px 0px",
                }}
              >
                {isLoading ? (
                  <CircularProgress
                    sx={{ color: theme.palette.primary.white }}
                    size={20}
                  />
                ) : (
                  "Log In"
                )}
              </Button>
            </DialogActions>
          </Box>
        </form>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "Center",
          }}
        >
          <Button
            variant="text"
            sx={{ fontSize: "15px", fontWeight: 600 }}
            onClick={handleForgot}
          >
            Forgot password?
          </Button>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "Center",
          }}
        >
          <Typography
            variant="h2"
            sx={{ fontSize: { xs: "13px", sm: "15px" } }}
          >
            Don't have an account?
          </Typography>
          <Button
            variant="text"
            sx={{ fontSize: "15px" }}
            onClick={handleSignUp}
          >
            Sign Up
          </Button>
        </Box>
      </Dialog>

      <ForgotPassword />
      <SignUp />
    </React.Fragment>
  );
}

import React, { useRef, useState } from "react";
import { Box, Grid, IconButton, Typography, useTheme } from "@mui/material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";

const mainBoxStyle = (theme) => {
  return {
    // border: `2px dashed rgba(233, 236, 239, 1)`,
    border: `2px dashed ${theme.palette.primary.main}`,
    borderRadius: "4px",
    // mt: 7,
    height: { xs: "150px", sm: "150px" },
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  };
};

const imageIcons = {
  width: "100%",
  height: "100%",
  borderRadius: "4px",
};

const Portfolio = ({ formik }) => {
  const theme = useTheme();
  const { setFieldValue, values } = formik;

  const fileInputRef = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);
  const fileInputRef4 = useRef(null);

  const [hovered, setHovered] = useState(false);
  const [hovered2, setHovered2] = useState(false);
  const [hovered3, setHovered3] = useState(false);
  const [hovered4, setHovered4] = useState(false);

  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [image3, setImage3] = useState(null);
  const [image4, setImage4] = useState(null);

  const handleHover = () => {
    setHovered(!hovered);
  };

  const handleHover2 = () => {
    setHovered2(!hovered2);
  };

  const handleHover3 = () => {
    setHovered3(!hovered3);
  };

  const handleHover4 = () => {
    setHovered4(!hovered4);
  };

  const handleImageUpload = async (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    setFieldValue("portfolio[0]", file);
    setImage1(URL.createObjectURL(file));
  };

  const handleImageUpload2 = async (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    setFieldValue("portfolio[1]", file);
    setImage2(URL.createObjectURL(file));
  };

  const handleImageUpload3 = async (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    setFieldValue("portfolio[2]", file);
    setImage3(URL.createObjectURL(file));
  };

  const handleImageUpload4 = async (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    setFieldValue("portfolio[3]", file);
    setImage4(URL.createObjectURL(file));
  };

  return (
    <React.Fragment>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12} sm={6} md={3}>
          <Box
            onClick={() => {
              fileInputRef.current.click();
            }}
            sx={mainBoxStyle(theme)}
            onMouseEnter={handleHover}
            onMouseLeave={handleHover}
          >
            {image1 || values?.portfolio?.[0] ? (
              <img
                src={image1 || values?.portfolio?.[0]}
                style={imageIcons}
                alt="avatar"
              />
            ) : (
              <Typography variant="subtitle1">Upload image</Typography>
            )}

            <input
              type="file"
              ref={fileInputRef}
              accept="image/jpeg, image/png"
              onChange={handleImageUpload}
              style={{ display: "none" }}
            />
            {hovered && (
              <IconButton
                component="label"
                htmlFor="upload-image"
                sx={{ position: "absolute" }}
                // onClick={() => fileInputRef.current.click()}
              >
                <PhotoCamera />
              </IconButton>
            )}
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Box
            onClick={() => {
              fileInputRef2.current.click();
            }}
            sx={mainBoxStyle(theme)}
            onMouseEnter={handleHover2}
            onMouseLeave={handleHover2}
          >
            {image2 || values?.portfolio?.[1] ? (
              <img
                src={image2 || values?.portfolio?.[1]}
                style={imageIcons}
                alt="avatar"
              />
            ) : (
              <Typography variant="subtitle1">Upload image</Typography>
            )}

            <input
              type="file"
              ref={fileInputRef2}
              accept="image/jpeg, image/png"
              onChange={handleImageUpload2}
              style={{ display: "none" }}
            />
            {hovered2 && (
              <IconButton
                component="label"
                htmlFor="upload-image"
                sx={{ position: "absolute" }}
                // onClick={() => fileInputRef2.current.click()}
              >
                <PhotoCamera />
              </IconButton>
            )}
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Box
            onClick={() => {
              fileInputRef3.current.click();
            }}
            sx={mainBoxStyle(theme)}
            onMouseEnter={handleHover3}
            onMouseLeave={handleHover3}
          >
            {image3 || values?.portfolio?.[2] ? (
              <img
                src={image3 || values?.portfolio?.[2]}
                style={imageIcons}
                alt="avatar"
              />
            ) : (
              <Typography variant="subtitle1">Upload image</Typography>
            )}

            <input
              type="file"
              ref={fileInputRef3}
              accept="image/jpeg, image/png"
              onChange={handleImageUpload3}
              style={{ display: "none" }}
            />
            {hovered3 && (
              <IconButton
                component="label"
                htmlFor="upload-image"
                sx={{ position: "absolute" }}
                // onClick={() => fileInputRef3.current.click()}
              >
                <PhotoCamera />
              </IconButton>
            )}
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Box
            onClick={() => {
              fileInputRef4.current.click();
            }}
            sx={mainBoxStyle(theme)}
            onMouseEnter={handleHover4}
            onMouseLeave={handleHover4}
          >
            {image4 || values?.portfolio?.[3] ? (
              <img
                src={image4 || values?.portfolio?.[3]}
                style={imageIcons}
                alt="avatar"
              />
            ) : (
              <Typography variant="subtitle1">Upload image</Typography>
            )}

            <input
              type="file"
              ref={fileInputRef4}
              accept="image/jpeg, image/png"
              onChange={handleImageUpload4}
              style={{ display: "none" }}
            />
            {hovered4 && (
              <IconButton
                component="label"
                htmlFor="upload-image"
                sx={{ position: "absolute" }}
                // onClick={() => fileInputRef4.current.click()}
              >
                <PhotoCamera />
              </IconButton>
            )}
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Portfolio;

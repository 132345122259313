import React from "react";
import {
  Avatar,
  Box,
  Button,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { CrossIcon, LiveIcon, ShareIcon, StatsIcon } from "assets/images";
import dayjs from "dayjs";
import Stats from "./Stats";
import copy from "copy-to-clipboard";
import Comments from "./comments";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router";
import { OpenDeleteModal, SetCommentId } from "store/slices/modalsSlice";
import { useDispatch, useSelector } from "react-redux";

const mainBox = (theme) => {
  return {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: "8px",
    padding: 2,
    background: theme.palette.primary.white,
    mb: 1,
    boxShadow: "0px 5px 11.3px 0px rgba(0, 0, 0, 0.21)",
  };
};

const sectionHeader = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  mb: 1,
};

const filterInfoStyle = {
  display: "flex",
  alignItems: "center",
  mr: { xs: "8px", sm: "12px", md: "15px" },
};

const firstLogo = {
  width: { xs: "15px", sm: "15px", md: "25px" },
  display: "flex",
  alignItems: "center",
};

const textStyle = {
  ml: { xs: "4px", sm: "4", md: "5px" },
  fontSize: { xs: "9px", sm: "12px", md: "15px" },
};

const mainText = {
  fontSize: { xs: "16px", sm: "25px", md: "32px" },
  textAlign: "left",
  width: "100%",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const shareButton = {
  fontSize: { xs: "10px", sm: "12px", md: "14px" },
  maxWidth: "90px",
  height: "23px",
  ml: 1.5,
  "& .MuiButton-endIcon": { ml: { xs: "4px", sm: "6px", md: "8px" } },
  "@media (max-width: 440px)": {
    mt: "12px",
    ml: "0px",
    maxWidth: "100%",
  },
};

const removeButton = {
  background: "red",
  boxShadow: `0 0 0 2px #fff, 0 0 0 4px red`,
  fontSize: { xs: "10px", sm: "12px", md: "14px" },
  maxWidth: "90px",
  height: "23px",
  ml: 1.5,
  "& .MuiButton-endIcon": { ml: { xs: "4px", sm: "6px", md: "6px" } },
  "@media (max-width: 440px)": {
    mt: "12px",
    ml: "0px",
    maxWidth: "100%",
  },
};

const shareIconStyle = {
  width: { xs: "10px", sm: "12px", md: "12px" },
  display: "flex",
  alignItems: "center",
};

const crossIconStyle = {
  width: { xs: "10px", sm: "12px", md: "14px" },
  display: "flex",
  alignItems: "center",
};

const statsButton = {
  fontSize: { xs: "10px", sm: "12px", md: "14px" },
  maxWidth: "85px",
  height: "23px",
  ml: 1.5,
  "& .MuiButton-endIcon": { ml: { xs: "4px", sm: "6px", md: "8px" } },
  "@media (max-width: 440px)": {
    mt: "12px",
    ml: "0px",
    maxWidth: "100%",
  },
};

const updateButton = {
  maxWidth: "80px",
  ml: 1.5,
  height: "23px",
  "& .MuiButton-endIcon": { ml: { xs: "2px", sm: "2px", md: "3px" } },
  "@media (max-width: 440px)": {
    mt: "12px",
    ml: "0px",
    maxWidth: "100%",
  },
};

const updateIconStyle = {
  width: { xs: "8px", sm: "10px", md: "14px" },
  display: "flex",
  alignItems: "center",
};

const commentsButton = {
  fontSize: { xs: "10px", sm: "12px", md: "14px" },
  maxWidth: "130px",
  height: "23px",
  minWidth: "100px",
  "@media (max-width: 440px)": {
    mt: "12px",
    ml: "0px",
    maxWidth: "100%",
  },
};

const Item = ({ data }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const queryClient = useQueryClient();
  const [value, setValue] = React.useState(false);
  const [showComments, setShowComments] = React.useState(false);
  // const deleteId = useSelector((state) => state.modals.commentsId);
  const [open, setOpen] = React.useState(false);

  const handleChange = (e) => {
    e.preventDefault();
    setShowComments(false);
    setValue(!value);
  };

  const handleShowComments = (e) => {
    e.preventDefault();
    setValue(false);
    setShowComments(!showComments);
  };
  const handleCopy = (e, companyId) => {
    e.preventDefault();
    setOpen(true);
    copy(`${window.location.origin}/company-listings/${companyId}`);
    setTimeout(() => {
      setOpen(false);
    }, 1000);
  };

  // const { mutate: deleteMutate, isLoading } = useMutation(deleteList, {
  //   onSuccess: (data) => {
  //     queryClient.invalidateQueries("getPersonalListing");
  //     queryClient.invalidateQueries("getAllListing");
  //     dispatch(SetCommentId(null));
  //     dispatch(OpenDeleteModal(false));
  //     toast.success("List remove successfully.");
  //   },
  //   onError: (error) => {
  //     toast.error(
  //       `${error?.response?.data?.message}` || "Something went wrong!"
  //     );
  //   },
  // });

  const handleOpen = (item) => {
    dispatch(SetCommentId(item));
    dispatch(OpenDeleteModal(true));
  };

  // const handleDelete = () => {
  //   deleteMutate(deleteId);
  // };

  const handleCompanyDetail = (e, id) => {
    e.preventDefault();
    navigate(`/view-listing/${id}`);
  };

  return (
    <Box sx={mainBox(theme)}>
      <Box sx={sectionHeader}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={filterInfoStyle}>
            <Box sx={firstLogo}>
              <img src={LiveIcon} alt="Car icon" width="100%" />
            </Box>
            <Typography variant="subtitle" sx={textStyle}>
              {data?.company?.live ? "Live" : "Archive"}
            </Typography>
          </Box>

          <Box sx={filterInfoStyle}>
            <Typography variant="subtitle" sx={textStyle}>
              (2,012) views
            </Typography>
          </Box>

          <Box sx={filterInfoStyle}>
            <Typography variant="subtitle" sx={textStyle}>
              $&nbsp;{data?.company?.asking_price?.toLocaleString()}
            </Typography>
          </Box>
        </Box>

        <Stack direction="row" spacing={1}>
          <Avatar
            src={data?.user?.profile_image || "/broken-image.jpg"}
            sx={{
              width: { xs: "20px", sm: "30px", md: "30px" },
              height: { xs: "20px", sm: "30px", md: "30px" },
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography
              variant="subtitle"
              sx={{ fontSize: { xs: "9px", sm: "12px", md: "12px" } }}
            >
              {data?.user?.username}
            </Typography>
            <Typography
              variant="subtitle"
              sx={{
                fontSize: { xs: "9px", sm: "12px", md: "12px" },
                fontWeight: 400,
              }}
            >
              {data?.user?.created_at &&
                dayjs(data?.user?.created_at).format("MMMM DD, YYYY")}
            </Typography>
          </Box>
        </Stack>
      </Box>
      <Box
        sx={{ maxWidth: "900px", cursor: "pointer" }}
        onClick={(e) => handleCompanyDetail(e, data?.company?.id)}
      >
        <Typography variant="h2" sx={mainText}>
          {data?.company?.name}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mt: 1.5,
          "@media (max-width: 440px)": {
            display: "flex",
            flexWrap: "wrap",
          },
        }}
      >
        <Button
          variant="contained"
          sx={commentsButton}
          onClick={handleShowComments}
        >
          Comments
        </Button>
        <Button
          onClick={handleChange}
          variant="contained"
          sx={statsButton}
          // endIcon={
          //   <Box sx={statsIconStyle}>
          //     <img src={StatsIcon} alt="stats icon" width="100%" />
          //   </Box>
          // }
        >
          Details
        </Button>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title="Copied"
          placement="top-start"
        >
          <Button
            variant="contained"
            onClick={(e) => handleCopy(e, data?.company?.id)}
            sx={shareButton}
            endIcon={
              <Box sx={shareIconStyle}>
                <img src={ShareIcon} alt="stats icon" width="100%" />
              </Box>
            }
          >
            Share
          </Button>
        </Tooltip>
        <Button
          variant="contained"
          sx={updateButton}
          onClick={() => navigate(`/edit-listing/${data?.company?.id}`)}
          endIcon={
            <Box sx={updateIconStyle}>
              <EditIcon sx={{ width: "0.8rem", height: "0.8rem" }} />
            </Box>
          }
        >
          Update
        </Button>
        <Button
          variant="contained"
          sx={removeButton}
          onClick={() => handleOpen(data?.company?.id)}
          // disabled={isLoading}
          endIcon={
            <Box sx={crossIconStyle}>
              <img src={CrossIcon} alt="stats icon" width="100%" />
            </Box>
          }
        >
          Remove
        </Button>
      </Box>
      <Box sx={{ maxHeight: "700px", overflowY: "scroll" }}>
        {value && (
          <Box sx={{ padding: "20px 20px" }}>
            <Stats data={data && data?.company} />
          </Box>
        )}

        {showComments && (
          <Box sx={{ padding: "20px 20px" }}>
            <Comments id={data && data?.company?.id} />
          </Box>
        )}
      </Box>
      {/* <DeleteModal handleDelete={handleDelete} /> */}
    </Box>
  );
};

export default Item;

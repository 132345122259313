import { Box, Typography, useTheme } from "@mui/material";
import { infoIcon } from "assets/images";
import React from "react";
import theme from "theme";

const infoBox = {
  height: "27px",
  background: "rgb(131 190 255 / 12%)",
  borderRadius: "24px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "0px 16px",
};

const infoBox2 = {
  height: "27px",
  borderRadius: "24px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "0px 16px",
};

const Stats = ({ data }) => {
  const theme = useTheme();
  return (
    <Box>
      <Typography
        variant="subtitle1"
        sx={{
          textAlign: "left",
          fontSize: "18px",
          fontWeight: 600,
          color: theme.palette.primary.dark,
          mb: 3,
        }}
        dangerouslySetInnerHTML={{ __html: data?.description }}
      />
      {/* <Box sx={infoBox}>
        <Typography variant="subtitle5">Sale by</Typography>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <Typography variant="subtitle5" sx={{ mr: 1 }}>
            {data?.owner}
          </Typography>
          <img src={infoIcon} alt="info Icon" />
        </Box>
      </Box> */}
      <Box sx={infoBox}>
        <Typography variant="subtitle5">Industry</Typography>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <Typography variant="subtitle5" sx={{ mr: 1 }}>
            {data?.industry || "--"}
          </Typography>
        </Box>
      </Box>
      <Box sx={infoBox2}>
        <Typography variant="subtitle5">Catagory</Typography>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <Typography variant="subtitle5" sx={{ mr: 1 }}>
            {data?.sub_industry || "--"}
          </Typography>
        </Box>
      </Box>
      <Box sx={infoBox}>
        <Typography variant="subtitle5">State</Typography>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <Typography variant="subtitle5" sx={{ mr: 1 }}>
            {data?.state || "--"}
          </Typography>
        </Box>
      </Box>
      <Box sx={infoBox2}>
        <Typography variant="subtitle5">City</Typography>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <Typography variant="subtitle5" sx={{ mr: 1 }}>
            {data?.city || "--"}
          </Typography>
        </Box>
      </Box>
      <Box sx={infoBox}>
        <Typography variant="subtitle5">Established</Typography>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <Typography variant="subtitle5" sx={{ mr: 1 }}>
            {data?.established || "--"}
          </Typography>
        </Box>
      </Box>
      {/* <Box sx={infoBox}>
        <Typography variant="subtitle5">Timeframe</Typography>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <Typography variant="subtitle5" sx={{ mr: 1 }}>
            {data?.timeframe}
          </Typography>
          <img src={infoIcon} alt="info Icon" />
        </Box>
      </Box> */}
      {/* <Box sx={infoBox2}>
        <Typography variant="subtitle5">Lifestyle</Typography>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <Typography variant="subtitle5" sx={{ mr: 1 }}>
            {data?.lifestyle}
          </Typography>
          <img src={infoIcon} alt="info Icon" />
        </Box>
      </Box> */}
      {/* <Box sx={infoBox}>
        <Typography variant="subtitle5">Reason</Typography>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <Typography variant="subtitle5" sx={{ mr: 1 }}>
            {data?.reason}
          </Typography>
          <img src={infoIcon} alt="info Icon" />
        </Box>
      </Box> */}
      <Box sx={infoBox2}>
        <Typography variant="subtitle5">Asking price</Typography>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <Typography variant="subtitle5" sx={{ mr: 1 }}>
            $ {data?.asking_price || "--"}
          </Typography>
        </Box>
      </Box>
      <Box sx={infoBox}>
        <Typography variant="subtitle5">Gross revenue</Typography>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <Typography variant="subtitle5" sx={{ mr: 1 }}>
            $ {data?.gross_revenue || "--"}
          </Typography>
        </Box>
      </Box>
      <Box sx={infoBox2}>
        <Typography variant="subtitle5">Cash Flow</Typography>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <Typography variant="subtitle5" sx={{ mr: 1 }}>
            $ {data?.cash_flow}
          </Typography>
        </Box>
      </Box>
      <Box sx={infoBox}>
        <Typography variant="subtitle5">EBITDA</Typography>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <Typography variant="subtitle5" sx={{ mr: 1 }}>
            $ {data?.ebitda_value || "--"}
          </Typography>
        </Box>
      </Box>
      <Box sx={infoBox2}>
        <Typography variant="subtitle5">Real Estate</Typography>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <Typography variant="subtitle5" sx={{ mr: 1 }}>
            $ {data?.total_rent_with_utilities || "--"}
          </Typography>
        </Box>
      </Box>
      <Box sx={infoBox}>
        <Typography variant="subtitle5">Equipment</Typography>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <Typography variant="subtitle5" sx={{ mr: 1 }}>
            $ {data?.equipment_amount || "--"}
          </Typography>
        </Box>
      </Box>
      <Box sx={infoBox2}>
        <Typography variant="subtitle5">Inventory</Typography>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <Typography variant="subtitle5" sx={{ mr: 1 }}>
            $ {data?.inventory_amount || "--"}
          </Typography>
        </Box>
      </Box>
      <Box sx={infoBox}>
        <Typography variant="subtitle5">Operating expenses</Typography>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <Typography variant="subtitle5" sx={{ mr: 1 }}>
            $ {data?.operating_expenses || "--"}
          </Typography>
        </Box>
      </Box>
      <Box sx={infoBox2}>
        <Typography variant="subtitle5">Employees</Typography>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <Typography variant="subtitle5" sx={{ mr: 1 }}>
            {data?.employees_expense || "--"}
          </Typography>
        </Box>
      </Box>
      {/* <Box sx={infoBox}>
        <Typography variant="subtitle5">Emissions</Typography>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <Typography variant="subtitle5" sx={{ mr: 1 }}>
            --
          </Typography>
          <img src={infoIcon} alt="info Icon" />
        </Box>
      </Box> */}
      {/* <Box sx={infoBox}>
        <Typography variant="subtitle5">Clients</Typography>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <Typography variant="subtitle5" sx={{ mr: 1 }}>
            {data?.clients_count}
          </Typography>
          <img src={infoIcon} alt="info Icon" />
        </Box>
      </Box> */}
      {/* <Box sx={infoBox2}>
        <Typography variant="subtitle5">Social media</Typography>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <Typography variant="subtitle5" sx={{ mr: 1 }}>
            {data?.social_media_count}
          </Typography>
          <img src={infoIcon} alt="info Icon" />
        </Box>
      </Box>
      <Box sx={infoBox}>
        <Typography variant="subtitle5">Email list</Typography>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <Typography variant="subtitle5" sx={{ mr: 1 }}>
            {data?.email_list_count}
          </Typography>
          <img src={infoIcon} alt="info Icon" />
        </Box>
      </Box> */}
      <Box sx={infoBox}>
        <Typography variant="subtitle5">Website</Typography>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <Typography variant="subtitle5" sx={{ mr: 1 }}>
            {data?.website || "--"}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Stats;

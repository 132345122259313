import { Box, InputAdornment, TextField } from "@mui/material";
import {
  getFilterCities,
  getFilterLocation,
  getIndustriesDropdown,
} from "api/Listing";
import FilterSelect from "components/select/FilterSelect";
import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import SearchIcon from "@mui/icons-material/Search";
import { getLocation } from "api/Listing";

const inputStyle = {
  m: "20px 0px 10px 0px",
  "& .MuiOutlinedInput-root": {
    padding: "0px",
    height: { xs: "33px", sm: "35px", md: "40px" },
  },
  "& .MuiInputAdornment-root": {
    marginRight: "-7px",
  },
  "& ::placeholder": {
    textAlign: "center !important",
  },
  "&:-webkit-autofill": {
    "-webkit-box-shadow": "0 0 0 2px #fff, 0 0 0 4px #013eb796",
    "-webkit-text-fill-color": "#1D80EE",
  },
};

const Filter = ({ setFilter, filter }) => {
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  // Fetch listing data
  const { data } = useQuery({
    queryKey: ["getIndustriesDropdown"],
    // Specifies the function that fetches the data for the query
    queryFn: () => getIndustriesDropdown(),
    retry: false,
    refetchOnWindowFocus: false,
    notifyOnChangeProps: ["data", "error"],
  });

  // // Fetch listing data
  // const { data: stateData } = useQuery({
  //   queryKey: ["getFilterLocation"],
  //   queryFn: () => getFilterLocation(),
  //   retry: false,
  //   refetchOnWindowFocus: false,
  //   notifyOnChangeProps: ["data", "error"],
  //   onSuccess: (data) => {
  //     setStates(data);
  //   },
  // });

  // // Fetch listing data
  // const { data: citiesData } = useQuery({
  //   queryKey: ["getFilterCities"],
  //   queryFn: () => getFilterCities(),
  //   retry: false,
  //   refetchOnWindowFocus: false,
  //   notifyOnChangeProps: ["data", "error"],
  //   onSuccess: (data) => {
  //     console.log("data", data)
  //     setCities(data);
  //   },
  // });

  // Fetch locations data
  const { data: locationsData } = useQuery({
    queryKey: ["getLocation"],
    queryFn: () => getLocation(),
    retry: false,
    refetchOnWindowFocus: false,
    notifyOnChangeProps: ["data", "error"],
    onSuccess: (data) => {
      const arrayOfStates = data.map((obj) => obj.state);
      setStates(arrayOfStates);
    },
  });

  const handelSearch = (e) => {
    e.preventDefault();
    setFilter({ ...filter, query: e.target.value });
  };

  useEffect(() => {
    if (locationsData) {
      if (filter?.state && filter?.state !== "All") {
        const cityObject = locationsData.find((obj) => obj.state === filter?.state);
        setCities(cityObject?.cities);
      } else {
        setCities([]);
      }
    }
  }, [filter?.state]);

  return (
    <>
      <TextField
        name="query"
        placeholder="Search for business"
        value={filter?.query}
        fullWidth
        sx={inputStyle}
        onChange={handelSearch}
        autoComplete="off"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon sx={{ width: "35px" }} />
            </InputAdornment>
          ),
        }}
      />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <FilterSelect
          name="industry"
          placeholder="Industry"
          value={filter?.industry}
          options={data || []}
          filter={filter}
          setFilter={setFilter}
        />

        <FilterSelect
          name="state"
          placeholder="State"
          value={filter?.state}
          options={states}
          filter={filter}
          setFilter={setFilter}
        />

        <FilterSelect
          name="city"
          placeholder="City"
          value={filter?.city}
          options={cities}
          filter={filter}
          setFilter={setFilter}
        />
        {
          // <FilterSelect
          //   name="owner"
          //   placeholder="Filter"
          //   value={filter?.owner}
          //   filter={filter}
          //   setFilter={setFilter}
          //   options={["Owner", "Employee"]}
          // />
        }
      </Box>
    </>
  );
};

export default Filter;

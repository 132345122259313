import React from "react";
import { Box, Typography, Autocomplete, TextField, Paper } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTheme } from "@mui/material/styles";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import '../../Scrollbar.css';

const FilterSelect = (props) => {
  const { name, placeholder, options, value, handleBlur, setFilter, filter } =
    props;

  const theme = useTheme();

  const autocompleteStyle = {
    position: "relative",
    maxWidth: "225px",
    width: "32%",
    "& .MuiAutocomplete-endAdornment": {
      right: "5px !important",
    },

    "& .MuiAutocomplete-tag": {
      height: "25px",
      ml: "3px",
      mr: "3px",
      borderRadius: "4px",
    },
    "& .MuiChip-deleteIcon": {
      fontSize: "10px",
      fill: "#0575E6",
      display: "none",
    },
    "& .MuiAutocomplete-input": {
      padding: "11.5px 25px 11.5px 10px !important",
    },
    "& .MuiOutlinedInput-root": {
      padding: "0px 0px 0px 0px !important",
    },
  };

  const iconStyle = { fill: theme.palette.primary.main };

  const getOptionLabel = (option) => {
    if (typeof option === "string") {
      return option;
    } else if (typeof option === "number") {
      return option.toString();
    }
  };

  const dropdownListStyle = {
    width: "100%",
    color: theme.palette.primary.white,
    backgroundColor: theme.palette.primary.main,
    marginTop: "-35px",
    marginBottom: "-35px",
    borderRadius: "20px",
    boxShadow: `0 0 0 2px ${theme.palette.primary.main}, 0 0 0 4px ${theme.palette.primary.main}`,
  };

  const allOption = "All";
  const updatedOptions = [allOption, ...options];

  return (
    <React.Fragment>
      <Autocomplete
        disabled={false}
        noOptionsText={
          <Typography
            variant="subtitle1"
            sx={{
              textAlign: "left",
              color: theme.palette.primary.white,
              fontWeight: 500,
            }}
          >
            No options
          </Typography>
        }
        PaperComponent={({ children }) => (
          <Paper style={dropdownListStyle}>
            <Box
              sx={{
                display: "inline-flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "90%",
                paddingTop: "5px",
                paddingBottom: "5px",
                marginLeft: "5px",
                borderBottom: "1px solid #fff",
              }}
            >
              <Typography sx={{ fontWeight: 600 }}>{placeholder}</Typography>
              <KeyboardArrowUpIcon />
            </Box>

            {children}
          </Paper>
        )}
        popupIcon={<KeyboardArrowDownIcon sx={iconStyle} />}
        sx={autocompleteStyle}
        id="tags-outlined"
        clearIcon={null}
        name={name}
        options={updatedOptions.length > 1 ? updatedOptions : options}
        value={value || null}
        defaultValue={value}
        filterOptions={(options, params) => {
          const filtered = options;

          if (params.inputValue === "") {
            return filtered;
          }
          return filtered;
        }}
        getOptionLabel={(option) => getOptionLabel(option)}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              textAlign: "left",
              padding: "5px 5px",
            }}
          >
            <Typography sx={{ fontWeight: 600, fontSize: "15px" }}>
              {getOptionLabel(option)}
            </Typography>
          </li>
        )}
        onChange={(event, newValue) => {
          setFilter({ ...filter, [name]: newValue });
        }}
        onBlur={handleBlur}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor: "#fff",

                "& fieldset": {
                  borderColor: theme.palette.primary.main,
                  borderWidth: "2px",
                },
                "&:hover fieldset": {
                  borderColor: theme.palette.primary.main,
                },
                "&.Mui-focused fieldset": {
                  borderColor: theme.palette.primary.main,
                  borderRadius: "20px !important",
                  outline: "none",
                },
                "&.Mui-disabled fieldset": {
                  borderColor: theme.palette.primary.main,
                },
                "& .MuiOutlinedInput-input": {
                  padding: {
                    xs: "0px 22px 0px 8px",
                    sm: "0px 25px 0px 10px",
                    md: "0px 25px 0px 10px  !important",
                  },
                  textAlign: "left",
                  borderRadius: "20px !important",
                  height: { xs: "30px", sm: "33px", md: "35px" },
                },
                background: "rgba(255,255,255,0.1)",
                color: theme.palette.primary.dark,
                fontSize: "15px",
                fontWeight: 500,
                fontFamily: "inter",
                borderRadius: "20px !important",
                borderWidth: "2px",

                "& ::placeholder": {
                  color: theme.palette.primary.gray,
                  fontFamily: "inter",
                  fontSize: "15px",
                  textAlign: "center",
                  fontWeight: 500,
                  opacity: 1,
                  "@media (max-width: 600px)": {
                    fontSize: "13px",
                  },
                },
              },
              "& input": {
                caretColor: theme.palette.primary.main, // Change this to your desired cursor color
              },
              "& ::placeholder": {
                color: `${theme.palette.primary.main} !important`,
                textAlign: "left !important",
                fontWeight: `${600} !important`,
                letterSpacing: "-0.597px !important",
              },
            }}
          />
        )}
      />
    </React.Fragment>
  );
};

export default FilterSelect;

import React from "react";
import { Box, Typography } from "@mui/material";

const NoData = ({ height, text }) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: height,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography variant="subtitle" sx={{ mt: 1 }}>
        {text || "No record found!"}
      </Typography>{" "}
    </Box>
  );
};

export default NoData;

import * as React from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { OpenDeleteModal, SetCommentId } from "store/slices/modalsSlice";
import CancelIcon from "@mui/icons-material/Cancel";

const dialogStyle = {
  "& .MuiDialog-paper": {
    width: { xs: "270px", sm: "389px" },
    maxHeight: { xs: "203px", sm: "210px", md: "210px" },
    background: "#FAFAFA",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    boxRadius: "none",
    borderRadius: "8px",
    padding: "0px 0px 10px 0px",
  },
  "& .MuiDialog-container": {
    background: "rgba(255, 255, 255, 0.02)",
    backdropFilter: "blur(10px)",
  },
};

const DeleteModal = ({ handleDelete }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down("480px"));
  const open = useSelector((state) => state.modals.delete);

  const handleClose = () => {
    dispatch(SetCommentId(null));
    dispatch(OpenDeleteModal(false));
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      sx={dialogStyle}
      onClose={handleClose}
      data-cy={`delete-close`}
      aria-labelledby="responsive-dialog-title"
    >
      <Box
        sx={{
          position: "absolute",
          right: 9,
          top: 8,
          cursor: "pointer",
          zIndex: 1,
        }}
      >
        <CancelIcon
          sx={{ fontSize: "20px", cursor: "pointer" }}
          onClick={handleClose}
        />
      </Box>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "20px 50px",
          "@media (max-width: 480px)": {
            padding: "20px 10px",
          },
        }}
      >
        <Typography
          variant="h3"
          sx={{
            mb: 1,
            fontSize: "25px",
            "@media (max-width: 480px)": {
              fontSize: "20px",
            },
          }}
        >
          Are you sure?
        </Typography>
        <Typography variant="subtitle6">
          You will not be able to recover this!
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Button
          autoFocus
          onClick={handleClose}
          data-cy="cancel-submit"
          variant="contained"
          sx={{
            background: "gray",
            maxWidth: "100px",
            boxShadow: "none",
            padding: "10px 0px",
            "&:hover": {
              background: "gray",
            },
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleDelete}
          data-cy="delete-submit"
          autoFocus
          variant="contained"
          sx={{
            maxWidth: "100px",
            boxShadow: "none",
            padding: "10px 0px",
          }}
        >
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteModal;

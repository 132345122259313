import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import ProductInfo from "./components/ProductInfo";
import FinancialInfo from "./components/FinancialInfo";
import MarketingInfo from "./components/MarketingInfo";
import IndustoryModal from "components/modals/IndustoryModal";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { addListing, getSpecificList, updateListing } from "api/Listing";
import toast from "react-hot-toast";
import { addListingSchema } from "var/Schema";
import { useNavigate, useParams } from "react-router-dom";
import UploadAssets from "./components/UploadAssets";
import Portfolio from "./components/Portfolio";

const AddListing = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const param = useParams();
  const id = param?.id;
  const queryClient = useQueryClient();

  const [loading, setLoading] = useState(id ? true : false);
  const [image, setImage] = useState(null);

  // Fetch specific listing data
  const { data } = useQuery({
    queryKey: ["getSpecificList", id],
    // Specifies the function that fetches the data for the query
    queryFn: () => getSpecificList(id),
    retry: false,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: Boolean(id),
    notifyOnChangeProps: ["data", "error"],
  });

  // add listing mutation
  const { mutate, isLoading } = useMutation(addListing, {
    onSuccess: () => {
      queryClient.removeQueries("getAllListing");
      navigate("/");
      queryClient.invalidateQueries("getPersonalListing");
      queryClient.invalidateQueries("getAllListing");
      toast.success("List added successfully");
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message || "Something went wrong!");
    },
  });

  // add listing mutation
  const { mutate: updateMutate, isLoading: updateLoading } = useMutation(
    updateListing,
    {
      onSuccess: () => {
        queryClient.removeQueries("getAllListing");
        navigate("/");
        queryClient.invalidateQueries("getPersonalListing");
        queryClient.invalidateQueries("getAllListing");
        toast.success("List updated successfully");
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message || "Something went wrong!");
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      logo: null,
      portfolio: [],
      name: "",
      owner: "",
      industry: "",
      subIndustry: "",
      zipcode: "",
      city: "",
      state: "",
      established: "",
      timeframe: "",
      lifestyle: "",
      reason: "",
      askingprice: "",
      grossrevenue: "",
      cashflow: "",
      EBITDA: "",
      realestate: "",
      equipment: "",
      inventory: "",
      operatingexpenses: "",
      employees: "",
      clients: "",
      socialmedia: "",
      emaillist: "",
      website: "",
      description: "",
      swot: "",
      marketing: "",
      training: "",
      assets: "",
      wruselling: "",
      live: false,
    },
    validationSchema: addListingSchema,
    onSubmit: (values) => {
      const portfolioArray = values.portfolio.filter(
        (item) => item !== undefined
      );
      const formData = new FormData();
      formData.append("company[logo]", values?.logo);
      formData.append("company[name]", values?.name);
      formData.append("company[gross_revenue]", values?.grossrevenue);
      formData.append("company[cash_flow]", values?.cashflow);
      formData.append("company[ebitda_value]", values?.EBITDA);
      formData.append("company[total_rent_with_utilities]", values?.realestate);
      formData.append("company[equipment_amount]", values?.equipment);
      formData.append("company[inventory_amount]", values?.inventory);
      formData.append("company[operating_expenses]", values?.operatingexpenses);
      formData.append("company[clients_count]", values?.clients);
      formData.append("company[description]", values?.description);
      formData.append("company[website]", values?.website);
      formData.append("company[swot]", values?.swot);
      formData.append("company[employees_expense]", values?.employees);
      formData.append("company[social_media_count]", values?.socialmedia);
      formData.append("company[email_list_count]", values?.emaillist);
      formData.append("company[marketing]", values?.marketing);
      formData.append("company[assets]", values?.assets);
      formData.append("company[transition_plan]", values?.training);
      formData.append("company[owner]", values?.owner);
      formData.append("company[industry]", values?.industry);
      formData.append("company[zipcode]", values?.zipcode);
      formData.append("company[state]", values?.state);
      formData.append("company[established]", values?.established);
      formData.append("company[timeframe]", values?.timeframe);
      formData.append("company[city]", values?.city);
      formData.append("company[lifestyle]", values?.lifestyle);
      formData.append("company[reason]", values?.reason);
      formData.append("company[asking_price]", values?.askingprice);
      formData.append("company[sub_industry]", values?.subIndustry);
      formData.append("company[live]", !values?.live);
      portfolioArray.forEach((value, index) => {
        formData.append(`company[portfolio_images][${index}]`, value);
      });
      id ? updateMutate({ data: formData, id }) : mutate(formData);
    },
  });

  const { setFieldValue, handleSubmit } = formik;

  useEffect(() => {
    const editData = data?.data?.company;
    if (editData) {
      setFieldValue("name", editData?.name);
      setFieldValue("owner", editData?.owner);
      setFieldValue("industry", editData?.industry);
      setFieldValue("subIndustry", editData?.sub_industry);
      setFieldValue("zipcode", editData?.zipcode);
      setFieldValue("state", editData?.state);
      setFieldValue("city", editData?.city);
      setFieldValue("established", editData?.established);
      setFieldValue("timeframe", editData?.timeframe);
      setFieldValue("lifestyle", editData?.lifestyle);
      setFieldValue("reason", editData?.reason);
      setFieldValue("askingprice", editData?.asking_price);
      setFieldValue("grossrevenue", editData?.gross_revenue);
      setFieldValue("cashflow", editData?.cash_flow);
      setFieldValue("EBITDA", editData?.ebitda_value);
      setFieldValue("realestate", editData?.total_rent_with_utilities);
      setFieldValue("equipment", editData?.equipment_amount);
      setFieldValue("inventory", editData?.inventory_amount);
      setFieldValue("operatingexpenses", editData?.operating_expenses);
      setFieldValue("employees", editData?.employees_expense);
      setFieldValue("clients", editData?.clients_count);
      setFieldValue("socialmedia", editData?.social_media_count);
      setFieldValue("emaillist", editData?.email_list_count);
      setFieldValue("website", editData?.website);
      setFieldValue("description", editData?.description);
      setFieldValue("swot", editData?.swot);
      setFieldValue("marketing", editData?.marketing);
      setFieldValue("training", editData?.transition_plan);
      setFieldValue("assets", editData?.assets);
      setFieldValue("live", !editData?.live);
      setFieldValue("portfolio", editData?.portfolio_images || []);
      setFieldValue("logo", editData?.logo);

      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [data]);

  return (
    <React.Fragment>
      {updateLoading || loading ? (
        <Box
          sx={{
            // position: "absolute",
            width: "100%",
            height: "90vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={34} sx={{ mb: 3 }} />
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
            flexDirection: "column",
            padding: { xs: "10px 20px", sm: "20px 30px" },
          }}
        >
          <UploadAssets setImage={setImage} image={image} formik={formik} />

          <Typography
            variant="h3"
            sx={{ fontSize: { xs: "24px", sm: "32px" } }}
          >
            Business Information
          </Typography>
          <Typography
            variant="subtitle"
            sx={{
              mb: 4,
              textAlign: "left",
              fontSize: { xs: "12px", sm: "15px" },
            }}
          >
            Enter your business information below. This
          </Typography>

          <ProductInfo formik={formik} />

          <Typography
            variant="h3"
            sx={{ mt: 4, fontSize: { xs: "24px", sm: "32px" } }}
          >
            Financial Information
          </Typography>
          <Typography
            variant="subtitle"
            sx={{
              mb: 4,
              textAlign: "left",
              fontSize: { xs: "12px", sm: "15px" },
            }}
          >
            Use your overall average annual expenses.
          </Typography>

          <FinancialInfo formik={formik} />

          <Typography
            variant="h3"
            sx={{ mt: 4, fontSize: { xs: "24px", sm: "32px" } }}
          >
            Marketing Information
          </Typography>
          <Typography
            variant="subtitle"
            sx={{
              mb: 4,
              textAlign: "left",
              fontSize: { xs: "12px", sm: "15px" },
            }}
          >
            Include the total amounts.
          </Typography>

          <MarketingInfo formik={formik} />

          <Typography
            variant="h3"
            sx={{ mt: 4, fontSize: { xs: "24px", sm: "32px" } }}
          >
            Portfolio Images
          </Typography>
          <Typography
            variant="subtitle"
            sx={{
              mb: 4,
              textAlign: "left",
              fontSize: { xs: "12px", sm: "15px" },
            }}
          >
            Attach business portfolio images
          </Typography>
          <Portfolio formik={formik} />

          <Typography
            variant="subtitle6"
            sx={{ fontSize: "14px", textAlign: "left", mb: 3 }}
          >
            DealRanker will share any volunteer information that you select or
            write above. Leave anything blank to hide information from your
            public listing. Please use your average year over year expenses.
            Buyer(s) will have the right to review your bank statements before
            they purchase your company. Please try your best at accounting for
            each field suggested above.
          </Typography>

          <IndustoryModal setFieldValue={setFieldValue} />

          <Button
            variant="contained"
            type="submit"
            onClick={handleSubmit}
            sx={{ mb: 3 }}
            disabled={isLoading || updateLoading}
          >
            {isLoading || updateLoading ? (
              <CircularProgress
                sx={{ color: theme.palette.primary.white }}
                size={15}
              />
            ) : (
              "Submit"
            )}
          </Button>
        </Box>
      )}
    </React.Fragment>
  );
};

export default AddListing;

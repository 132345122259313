import React from "react";
import { Box, Typography, Autocomplete, TextField, Paper } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTheme } from "@mui/material/styles";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const Select = (props) => {
  const {
    name,
    placeholder,
    options,
    value,
    handleBlur,
    setFieldValue,
    helperText,
    error,
    setFieldTouched,
  } = props;

  const theme = useTheme();

  const autocompleteStyle = {
    maxWidth: "600px",
    width: "100%",

    "& .css-1q60rmi-MuiAutocomplete-endAdornment": {
      right: "15px !important",
    },

    "& .MuiAutocomplete-tag": {
      height: "25px",
      ml: "3px",
      mr: "3px",
      borderRadius: "4px",
    },
    "& .MuiChip-deleteIcon": {
      fontSize: "15px",
      fill: "#0575E6",
    },
    "& .MuiAutocomplete-input": {
      padding: "11.5px 25px 11.5px 10px !important",
    },
    "& .MuiOutlinedInput-root": {
      padding: "0px 0px 0px 0px !important",
    },
  };

  const iconStyle = { fill: theme.palette.primary.main };

  const getOptionLabel = (option) => {
    if (typeof option === "string") {
      return option;
    } else if (typeof option === "number") {
      return option.toString();
    }
  };

  const dropdownListStyle = {
    width: "100%",
    color: theme.palette.primary.white,
    backgroundColor: theme.palette.primary.main,
    marginTop: "-40px",
    marginBottom: "-40px",
    borderRadius: "20px",
    boxShadow: `0 0 0 2px ${theme.palette.primary.main}, 0 0 0 4px ${theme.palette.primary.main}`,
  };

  return (
    <React.Fragment>
      <Autocomplete
        freeSolo={false}
        disabled={false}
        noOptionsText={
          <Typography
            variant="subtitle1"
            sx={{ textAlign: "left", fontWeight: 500 }}
          >
            No options
          </Typography>
        }
        PaperComponent={({ children }) => (
          <Paper style={dropdownListStyle}>
            <Box
              sx={{
                display: "inline-flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "90%",
                paddingTop: "5px",
                paddingBottom: "5px",
                marginLeft: "5px",
                borderBottom: "1px solid #fff",
              }}
            >
              <Typography sx={{ fontWeight: 600 }}>{placeholder}</Typography>
              <KeyboardArrowUpIcon />
            </Box>

            {children}
          </Paper>
        )}
        popupIcon={<KeyboardArrowDownIcon sx={iconStyle} />}
        sx={autocompleteStyle}
        id="tags-outlined"
        name={name}
        options={options}
        value={value || null}
        defaultValue={value}
        filterOptions={(options, params) => {
          const filtered = options;
          if (params.inputValue === "") {
            return filtered;
          }
          return filtered;
        }}
        getOptionLabel={(option) => getOptionLabel(option)}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              textAlign: "left",
              padding: "5px 5px",
            }}
          >
            <Typography sx={{ fontWeight: 600, fontSize: "15px" }}>
              {getOptionLabel(option)}
            </Typography>
          </li>
        )}
        onChange={(event, newValue) => {
          setFieldValue(name, newValue);
        }}
        onBlur={handleBlur}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            onBlur={() => setFieldTouched(name, true)}
            error={error}
            helperText={helperText}
            sx={{
              "& .MuiOutlinedInput-root .MuiOutlinedInput-input": {
                padding: "0px 58px 0px 20px  !important",
                textAlign: "left",
                borderRadius: "20px !important",
              },

              "& input": {
                caretColor: theme.palette.primary.main, // Change this to your desired cursor color
              },
            }}
          />
        )}
      />
    </React.Fragment>
  );
};

export default Select;

import React from "react";
import Header from "./components/Header";
import Carousel from "./components/Carousel";
import {
  Box,
  CircularProgress,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import Info from "./components/Info";
import Comments from "./components/Comments";
import PropTypes from "prop-types";
import { useQuery } from "react-query";
import { getSpecificList } from "api/Listing";
import { useParams } from "react-router";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography sx={{ fontWeight: 600 }}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const tabStyle = (theme) => {
  return {
    borderRadius: "8px",
    border: `1px solid ${theme.palette.primary.gray}`,
    "& .MuiTabs-indicator": {
      backgroundColor: "transparent",
      borderRadius: "8px",
    },
    "& .MuiTab-root.Mui-selected": {
      background: theme.palette.primary.main,
      color: "#ffff",
      borderRadius: "6px",
    },
  };
};

const buttonStyle = {
  minWidth: "50%",
  maxWidth: "50%",
};

const DetailPage = () => {
  const theme = useTheme();
  const param = useParams();
  const id = param?.id;
  const [value, setValue] = React.useState(0);

  // Fetch specific listing data
  const { data, isLoading } = useQuery({
    queryKey: ["getSpecificList", id],
    // Specifies the function that fetches the data for the query
    queryFn: () => getSpecificList(id),
    retry: false,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: Boolean(id),
    notifyOnChangeProps: ["data", "error"],
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ padding: 2 }}>
      {isLoading ? (
        <Box
          sx={{
            width: "100%",
            height: "90vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={34} sx={{ mb: 3 }} />
        </Box>
      ) : (
        <>
          <Header data={data?.data} id={id} />
          <Carousel data={data?.data?.company} />
          <Box
            sx={{
              maxWidth: "960px",
              margin: "auto",
              mt: 2,
              borderRadius: "8px",
            }}
          >
            <Tabs
              sx={tabStyle(theme)}
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Details" {...a11yProps(0)} sx={buttonStyle} />
              <Tab label="Comments" {...a11yProps(1)} sx={buttonStyle} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={1}>
            <Comments id={id} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={0}>
            <Info data={data?.data?.company} />
          </CustomTabPanel>
        </>
      )}
    </Box>
  );
};

export default DetailPage;

import { apiIntercept } from "./Interceptors";

// get comments array
export const getComments = async (id) => {
  const jwt = localStorage.getItem("token");
  const { data: response } = await apiIntercept.get(
    `${process.env.REACT_APP_DEAL_RANKER_API}api/v1/companies/${id}/comments/index_comments`,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  );

  return response.data;
};

// get comments array
export const getAllComments = async (id) => {
  const jwt = localStorage.getItem("token");
  const { data: response } = await apiIntercept.get(
    `${process.env.REACT_APP_DEAL_RANKER_API}api/v1/companies/${id}/comments`,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  );

  return response.data;
};

// post comments array
export const postComments = async ({ data, id }) => {
  const jwt = localStorage.getItem("token");
  const { data: response } = await apiIntercept.post(
    `${process.env.REACT_APP_DEAL_RANKER_API}api/v1/companies/${id}/comments`,
    data,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  );

  return response.data;
};

// get replies
export const getReplies = async (parentId, companyId) => {
  const jwt = localStorage.getItem("token");
  const { data: response } = await apiIntercept.get(
    `${process.env.REACT_APP_DEAL_RANKER_API}api/v1/companies/${companyId}/comments/${parentId}`,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  );

  return response.data;
};

// reply post request
export const commentReply = async ({ parentId, companyId, data }) => {
  const jwt = localStorage.getItem("token");

  const { data: response } = await apiIntercept.post(
    `${process.env.REACT_APP_DEAL_RANKER_API}api/v1/companies/${companyId}/comments?parent_comment_id=${parentId}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  );
  return response;
};

export const rankUpReq = async (id) => {
  const jwt = localStorage.getItem("token");
  const { data: response } = await apiIntercept.patch(
    `${process.env.REACT_APP_DEAL_RANKER_API}api/v1/companies/${id}/rank_up`,
    {},
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  );
  return response;
};

export const rankDownReq = async (id) => {
  const jwt = localStorage.getItem("token");
  const { data: response } = await apiIntercept.patch(
    `${process.env.REACT_APP_DEAL_RANKER_API}api/v1/companies/${id}/rank_down`,
    {},
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  );
  return response;
};

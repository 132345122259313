import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import Select from "components/select/Autocomplete";
import {
  employeeList,
  getYearsArray,
  lifeStyleList,
  reasonsList,
  timeFrameList,
} from "utils/utils";
import { useDispatch } from "react-redux";
import { OpenIndustoryModal } from "store/slices/modalsSlice";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import EditIcon from "@mui/icons-material/Edit";
import TagIcon from "@mui/icons-material/Tag";
import { useQuery } from "react-query";
import { getLocation } from "api/Listing";

const textFieldStyle = () => {
  return {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-input": {
      padding: "0px 0px 0px 20px  !important",
      textAlign: "left",
      borderRadius: "20px 0px 0px 20px !important",
    },
  };
};

const ProductInfo = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const yearOptions = getYearsArray(1900, new Date().getFullYear());
  const { formik } = props;
  const {
    values,
    handleBlur,
    handleChange,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
  } = formik;

  const handleIndustryClick = (e) => {
    e.preventDefault();
    dispatch(OpenIndustoryModal(true));
  };

  // Fetch listing data
  const { data } = useQuery({
    queryKey: ["getLocation"],
    queryFn: () => getLocation(),
    retry: false,
    refetchOnWindowFocus: false,
    notifyOnChangeProps: ["data", "error"],
    onSuccess: (data) => {
      const arrayOfStates = data?.map((obj) => obj.state);
      setStates(arrayOfStates);
    },
  });

  useEffect(() => {
    if (data) {
      const cityObject = data.find((obj) => obj.state === values?.state);
      setCities(cityObject?.cities);
    }
  }, [data, values.state]);

  // useEffect(() => {
  //   // Check if there are errors and scroll to the first error
  //   const firstErrorField = Object.keys(errors)[0];
  //   const firstErrorElement = document.getElementsByName(firstErrorField)[0];

  //   if (firstErrorElement) {
  //     firstErrorElement.scrollIntoView({
  //       behavior: "smooth",
  //       block: "start",
  //     });
  //   }
  // }, [errors]);

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="name"
            placeholder="Name"
            autoComplete={`new-password-${new Date().getTime()}`}
            fullWidth
            value={values.name}
            onBlur={handleBlur}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <EditIcon sx={{ fontSize: "1.2rem" }} />
                </InputAdornment>
              ),
            }}
            inputProps={{
              maxLength: 50,
            }}
            sx={textFieldStyle}
            error={touched.name && Boolean(errors.name)}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="description"
            placeholder="Description"
            fullWidth
            value={values.description}
            onBlur={handleBlur}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <EditIcon sx={{ fontSize: "1.2rem" }} />
                </InputAdornment>
              ),
            }}
            autoComplete={`new-password-${new Date().getTime()}`}
            sx={textFieldStyle(theme)}
            error={touched.description && Boolean(errors.description)}
          />
          <Box sx={{ padding: "0px 15px" }}>
            <Typography variant="subtitle6" sx={{ fontSize: "12px" }}>
              Start with your elevator pitch.
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Select
            name="owner"
            placeholder="Owner"
            options={employeeList}
            value={values.owner}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            error={touched.owner && Boolean(errors.owner)}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Box
            sx={{
              marginBottom: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: "pointer",
              border:
                touched.industry && Boolean(errors.industry)
                  ? `2px solid ${theme.palette.primary.red}`
                  : `2px solid ${theme.palette.primary.main}`,
              borderRadius: "20px",
              padding: "0px 10px 0px 10px",
              height: { xs: "30px", sm: "33px", md: "35px" },
            }}
            onClick={handleIndustryClick}
          >
            <Typography
              variant="subtitle1"
              sx={{
                color: values?.industry
                  ? theme?.palette.primary.dark
                  : theme?.palette.primary.main,
                fontWeight: values?.industry ? 500 : 700,
              }}
            >
              {values?.industry || "Industry"}
            </Typography>
            <KeyboardArrowDownIcon sx={{ fill: theme.palette.primary.main }} />
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="zipcode"
            placeholder="Zip Code"
            type="number"
            fullWidth
            value={values.zipcode}
            onBlur={handleBlur}
            onChange={handleChange}
            onKeyPress={(event) => {
              if (event?.key === "-" || event?.key === "+") {
                event.preventDefault();
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <TagIcon sx={{ fontSize: "1rem", fontWeight: 900 }} />
                </InputAdornment>
              ),
            }}
            autoComplete={`new-password-${new Date().getTime()}`}
            sx={textFieldStyle}
            error={touched.zipcode && Boolean(errors.zipcode)}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Select
            name="state"
            placeholder="State"
            options={states}
            value={values.state}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            error={touched.state && Boolean(errors.state)}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Select
            name="city"
            placeholder="City/County"
            options={cities || []}
            value={values.city}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            error={touched.city && Boolean(errors.city)}
          />
          <Box sx={{ padding: "0px 15px" }}>
            <Typography variant="subtitle6" sx={{ fontSize: "12px" }}>
              &nbsp;
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Select
            name="reason"
            placeholder="Reason"
            options={reasonsList}
            value={values.reason}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            error={touched.reason && Boolean(errors.reason)}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Select
            name="established"
            placeholder="Established"
            options={yearOptions}
            value={values.established}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            error={touched.established && Boolean(errors.established)}
          />
          <Box sx={{ padding: "0px 15px" }}>
            <Typography variant="subtitle6" sx={{ fontSize: "12px" }}>
              When did your business originate?
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Select
            name="timeframe"
            placeholder="Timeframe"
            options={timeFrameList}
            value={values.timeframe}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            error={touched.timeframe && Boolean(errors.timeframe)}
          />
          <Box sx={{ padding: "0px 15px" }}>
            <Typography variant="subtitle6" sx={{ fontSize: "12px" }}>
              When do you need to sell your company?
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Select
            name="lifestyle"
            placeholder="Business structure"
            options={lifeStyleList}
            value={values.lifestyle}
            handleBlur={handleBlur}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            error={touched.lifestyle && Boolean(errors.lifestyle)}
          />
          <Box sx={{ padding: "0px 15px" }}>
            <Typography variant="subtitle6" sx={{ fontSize: "12px" }}>
              Choose (1) one that best describes the size.
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="swot"
            placeholder="SWOT"
            fullWidth
            value={values.swot}
            onBlur={handleBlur}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <EditIcon sx={{ fontSize: "1.2rem" }} />
                </InputAdornment>
              ),
            }}
            autoComplete={`new-password-${new Date().getTime()}`}
            sx={textFieldStyle(theme)}
            error={touched.swot && Boolean(errors.swot)}
          />
          <Box sx={{ padding: "0px 15px" }}>
            <Typography variant="subtitle6" sx={{ fontSize: "12px" }}>
              Strength, weakness, opportunity & threats.
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="marketing"
            placeholder="Marketing"
            fullWidth
            value={values.marketing}
            onBlur={handleBlur}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <EditIcon sx={{ fontSize: "1.2rem" }} />
                </InputAdornment>
              ),
            }}
            autoComplete={`new-password-${new Date().getTime()}`}
            sx={textFieldStyle(theme)}
            error={touched.marketing && Boolean(errors.marketing)}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="training"
            placeholder="Training / Transition Plan"
            fullWidth
            value={values.training}
            onBlur={handleBlur}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <EditIcon sx={{ fontSize: "1.2rem" }} />
                </InputAdornment>
              ),
            }}
            autoComplete={`new-password-${new Date().getTime()}`}
            sx={textFieldStyle(theme)}
            error={touched.training && Boolean(errors.training)}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="assets"
            placeholder="Real Estate / Assets"
            fullWidth
            value={values.assets}
            onBlur={handleBlur}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <EditIcon sx={{ fontSize: "1.2rem" }} />
                </InputAdornment>
              ),
            }}
            autoComplete={`new-password-${new Date().getTime()}`}
            sx={textFieldStyle(theme)}
            error={touched.assets && Boolean(errors.assets)}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ProductInfo;

import { apiIntercept } from "./Interceptors";

export const signup = async (data) => {
  const { data: response } = await apiIntercept.post(
    `${process.env.REACT_APP_DEAL_RANKER_API}api/v1/users/sign_up`,
    data
  );
  return response;
};

export const updateUser = async ({ data, id }) => {
  const jwt = localStorage.getItem("token");

  const { data: response } = await apiIntercept.patch(
    `${process.env.REACT_APP_DEAL_RANKER_API}api/v1/users/update/${id}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  );
  localStorage.setItem("token", response?.data?.token);
  return response;
};

export const changePassword = async (data) => {
  const jwt = localStorage.getItem("token");

  const { data: response } = await apiIntercept.patch(
    `${process.env.REACT_APP_DEAL_RANKER_API}api/v1/users/change_password`,
    data,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  );
  localStorage.setItem("token", response?.data?.token);
  return response;
};

export const login = async (data) => {
  const { data: response } = await apiIntercept.post(
    `${process.env.REACT_APP_DEAL_RANKER_API}api/v1/auth/login`,
    data
  );
  localStorage.setItem("token", response?.data?.token);
  return response?.token;
};

export const forgot = async (data) => {
  const { data: response } = await apiIntercept.post(
    `${process.env.REACT_APP_DEAL_RANKER_API}api/v1/password_reset`,
    data
  );
  return response;
};

import React, { Suspense, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Listing from "views/listing";
import MainLayout from "layout";
import { useDispatch, useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { userAuthenticate, userInfo } from "store/slices/authSlice";
import toast from "react-hot-toast";
import AddListing from "views/addlisting";
import ScrollToTop from "components/scrolltop";
import DetailPage from "views/detailpage";
import EditProfile from "views/editprofile";

const AppRoutes = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth.auth.isAuthenticate);

  const calculateExpTime = (token) => {
    try {
      const decoded = jwtDecode(token);
      const tokenExpirationTime = decoded.exp * 1000;
      const timeUntilExpiration = tokenExpirationTime - Date.now();
      return timeUntilExpiration;
    } catch {
      handleInvalidToken();
    }
  };

  const handleInvalidToken = () => {
    localStorage.removeItem("token");
    dispatch(userAuthenticate(false));
    dispatch(userInfo(null));
    localStorage.removeItem("token");
    // You can also return the invalid token here if needed.
    // return invalidToken;
  };

  const handleAuthentication = (jwt) => {
    const currentTime = Date.now() / 1000;

    if (jwt) {
      try {
        const { user_id, username, email, created_at, url, role, exp } =
          jwtDecode(jwt);
        const user = {
          userId: user_id,
          userName: username,
          email,
          url,
          role,
          createdAt: created_at,
        };
        if (exp > currentTime && user_id) {
          dispatch(userAuthenticate(true));
          dispatch(userInfo(user));
        } else {
          handleInvalidToken();
        }
      } catch (error) {
        handleInvalidToken();
      }
    } else {
      handleInvalidToken();
    }
  };

  useEffect(() => {
    const jwt = localStorage.getItem("token");
    handleAuthentication(jwt);

    // Set up the timeout only if the user is authenticated
    if (jwt && auth) {
      const timeoutId = setTimeout(() => {
        dispatch(userAuthenticate(false));
        dispatch(userInfo(null));
        localStorage.removeItem("token");
        toast.error("Session expired!");
      }, calculateExpTime(jwt));

      // Clear the timeout when the component unmounts or when the dependency changes
      return () => clearTimeout(timeoutId);
    }
    // eslint-disable-next-line
  }, [auth]);

  return (
    <Suspense fallback={true}>
      <MainLayout>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Listing />} />
          <Route path="/company-listings" element={<Listing />} />
          <Route path="/company-listings/:id" element={<Listing />} />
          <Route path="/view-listing/:id" element={<DetailPage />} />
          <Route element={<PrivateRoute />}>
            <Route path="/add-listing" element={<AddListing />} />
            <Route path="/edit-listing/:id" element={<AddListing />} />
            <Route path="/profile" element={<EditProfile />} />
          </Route>
        </Routes>
      </MainLayout>
    </Suspense>
  );
};

export default AppRoutes;

import React, { useState } from "react";
import {
  Box,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { LinkIcon } from "assets/images";
import TagIcon from "@mui/icons-material/Tag";


const textFieldStyle = (theme) => {
  return {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-input": {
      padding: "0px 0px 0px 20px  !important",
      textAlign: "left",
      borderRadius: "20px 0px 0px 20px !important",
    },
  };
};


const MarketingInfo = (props) => {
  const theme = useTheme();
  const { values, handleBlur, handleChange, touched, errors ,setFieldValue} = props.formik;


  const toggleSwitch = () => {
    setFieldValue("live", !values?.live);

  };

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="clients"
            placeholder="Clients"
            type="number"
            fullWidth
            value={values.clients}
            onBlur={handleBlur}
            onChange={handleChange}
            onKeyPress={(event) => {
              if (event?.key === "-" || event?.key === "+") {
                event.preventDefault();
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <TagIcon sx={{ fontSize: "1rem", fontWeight: 900 }} />
                </InputAdornment>
              ),
            }}
            autoComplete="off"
            sx={textFieldStyle(theme)}
            error={touched.clients && Boolean(errors.clients)}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="socialmedia"
            placeholder="Social Media Followers"
            type="number"
            fullWidth
            value={values.socialmedia}
            onBlur={handleBlur}
            onChange={handleChange}
            onKeyPress={(event) => {
              if (event?.key === "-" || event?.key === "+") {
                event.preventDefault();
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <TagIcon sx={{ fontSize: "1rem", fontWeight: 900 }} />
                </InputAdornment>
              ),
            }}
            autoComplete="off"
            sx={textFieldStyle(theme)}
            error={touched.socialmedia && Boolean(errors.socialmedia)}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="emaillist"
            placeholder="Email List"
            type="number"
            fullWidth
            value={values.emaillist}
            onBlur={handleBlur}
            onChange={handleChange}
            onKeyPress={(event) => {
              if (event?.key === "-" || event?.key === "+") {
                event.preventDefault();
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <TagIcon sx={{ fontSize: "1rem", fontWeight: 900 }} />
                </InputAdornment>
              ),
            }}
            autoComplete="off"
            sx={textFieldStyle(theme)}
            error={touched.emaillist && Boolean(errors.emaillist)}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="website"
            placeholder="Website"
            fullWidth
            value={values.website}
            onBlur={handleBlur}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <img src={LinkIcon} alt="link icon" />
                </InputAdornment>
              ),
            }}
            autoComplete="off"
            sx={textFieldStyle(theme)}
            error={touched.website && Boolean(errors.website)}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant="subtitle"
            sx={{ fontSize: { xs: "16px", sm: "20px" } }}
          >
            Listing Status
          </Typography>
        

<Box
      sx={{
        m: 1,
        width: "100px",
        height: "40px",
        background: values.live ? "#CFCFCF" : "#1D80EE",
        borderRadius: "25px",
        position: "relative",
        cursor: "pointer",
        transition: "background-color 0.3s ease",
        display: "flex",
        justifyContent:values.live ? "flex-start": "flex-end",
        alignItems: "center"
      }}
      onClick={toggleSwitch}
    >
      <Typography sx={{
      color: "#fff",
      fontSize:"14px",
      mr:2,
      ml:0.8,
      fontWeight: 600,}}>{!values.live ?"Live":"Archive"}</Typography>
      <Box
        sx={{
          width: "38px",
          height: "38px",
          background: values.live ? "#fff" : "#4C6C8F",
          position: "absolute",
          left: values.live ? "60px" : "-1px",
          top: "0px",
          borderRadius: "50%",
          border:values.live ? "1px solid #CFCFCF":"1px solid #fff",
          transition: "left 0.3s ease, background-color 0.3s ease",
        }}
      ></Box>
    </Box>


        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default MarketingInfo;

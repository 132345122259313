import * as React from "react";
import {
  TextField,
  Box,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useFormik } from "formik";
import CancelIcon from "@mui/icons-material/Cancel";
import { BackIcon } from "assets/images";
import DialogActions from "@mui/material/DialogActions";
import { signupSchema } from "var/Schema";
import { useDispatch, useSelector } from "react-redux";
import { OpenLoginModal, OpenSignupModal } from "store/slices/modalsSlice";
import { signup } from "api/Auth";
import { useMutation } from "react-query";
import toast from "react-hot-toast";
import { getPrivacyPolicy } from "api/FAQS";

const dialogStyle = {
  "& .MuiDialog-paper": {
    width: { xs: "270px", sm: "389px" },
    maxHeight: { xs: "465px", sm: "465px", md: "465px" },
    minHeight: "400px",
    background: "#FAFAFA",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    boxRadius: "none",
    borderRadius: "8px",
  },
  "& .MuiDialog-container": {
    background: "rgba(255, 255, 255, 0.02)",
    backdropFilter: "blur(10px)",
  },
};

const dialogHeader = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: { xs: "20px 20px 20px 20px", sm: "20px 20px 20px 40px" },
};

export default function SignUp() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const open = useSelector((state) => state.modals.signup);

  // Define a mutation for signup
  const { mutate, isLoading } = useMutation(signup, {
    onSuccess: (data) => {
      handleClose();
      toast.success(
        "Thank you for creating an account! We've sent a confirmation link to your email."
      );
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message || "Something went wrong!");
    },
  });

  // Define a mutation for privacy
  const { mutate: mutatePrivacyPolicy, isLoading: getPrivacyPolicyLoading } =
    useMutation(getPrivacyPolicy, {
      onSuccess: (data) => {
        window.open(data, "_blank");
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message || "Something went wrong!");
      },
    });
  // Formik object for managing form state and validation

  const formik = useFormik({
    initialValues: {
      username: "",
      email: "",
      password: "",
    },
    validationSchema: signupSchema,
    onSubmit: (values) => {
      const data = {
        user: {
          email: values.email,
          username: values.username,
          password: values.password,
          password_confirmation: values.password,
        },
      };
      mutate(data);
    },
  });

  const {
    values,
    handleBlur,
    handleSubmit,
    handleChange,
    touched,
    errors,
    resetForm,
  } = formik;

  const handleClose = () => {
    dispatch(OpenSignupModal(false));
    resetForm();
  };

  const handleSignIn = (e) => {
    e.preventDefault();
    dispatch(OpenLoginModal(true));
    handleClose();
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        sx={dialogStyle}
        onClose={handleClose}
      >
        <Box sx={dialogHeader}>
          {/* <Box
            sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={handleClose}
          >
            <img
              src={BackIcon}
              width="22px"
              alt="back icon"
              style={{ marginTop: "4px" }}
            />
            <Typography variant="subtitle1" sx={{ ml: "10px" }}>
              Go Back
            </Typography>
          </Box> */}
          <CancelIcon
            sx={{ fontSize: "20px", cursor: "pointer" }}
            onClick={handleClose}
          />
        </Box>

        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: "30px", sm: "35px" },
            letterSpacing: "-1.715px",
          }}
        >
          Join our Community
        </Typography>
        <Typography variant="h2" sx={{ fontSize: { xs: "13px", sm: "15px" } }}>
          Buy, Sell
        </Typography>

        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              maxWidth: "300px",
              margin: "auto",
              padding: "30px 10px",
              textAlign: "center",
            }}
          >
            <TextField
              name="username"
              placeholder="Username"
              fullWidth
              value={values.username}
              onBlur={handleBlur}
              sx={{
                mb: "10px",
                "& ::placeholder": {
                  textAlign: "center !important",
                  fontWeight: `${500} !important`,
                  color: `${theme.palette.primary.gray} !important`,
                  opacity: 1,
                },
              }}
              onChange={handleChange}
              autoComplete="dkasdss"
              error={touched.username && Boolean(errors.username)}
              helperText={
                touched.username &&
                Boolean(errors.username) &&
                touched.username &&
                errors.username
              }
            />

            <TextField
              name="email"
              placeholder="Email"
              fullWidth
              value={values.email}
              onBlur={handleBlur}
              onChange={handleChange}
              sx={{
                mb: "10px",
                "& ::placeholder": {
                  textAlign: "center !important",
                  fontWeight: `${500} !important`,
                  color: `${theme.palette.primary.gray} !important`,
                  opacity: 1,
                },
              }}
              autoComplete="dkasdss"
              error={touched.email && Boolean(errors.email)}
              helperText={
                touched.email &&
                Boolean(errors.email) &&
                touched.email &&
                errors.email
              }
            />

            <TextField
              name="password"
              type="password"
              placeholder="Password"
              fullWidth
              value={values.password}
              onBlur={handleBlur}
              onChange={handleChange}
              sx={{
                mb: "10px",
                "& ::placeholder": {
                  textAlign: "center !important",
                  fontWeight: `${500} !important`,
                  color: `${theme.palette.primary.gray} !important`,
                  opacity: 1,
                },
              }}
              autoComplete="dkasdss"
              error={touched.password && Boolean(errors.password)}
              helperText={
                touched.password &&
                Boolean(errors.password) &&
                touched.password &&
                errors.password
              }
            />

            <Typography
              variant="subtitle6"
              sx={{
                fontWeight: 400,
                fontSize: "12px",
              }}
            >
              By clicking Agree & Join, you agree to the Dealranker{" "}
              <span
                style={{
                  color: theme.palette.primary.main,
                  fontWeight: 600,
                  cursor: "pointer",
                }}
                onClick={() => mutatePrivacyPolicy()}
              >
                User Agreement
              </span>
              ,&nbsp;
              <span
                style={{
                  color: theme.palette.primary.main,
                  fontWeight: 600,
                  cursor: "pointer",
                }}
                onClick={() => mutatePrivacyPolicy()}
              >
                Privacy Policy
              </span>
              , and&nbsp;
              <span
                style={{
                  color: theme.palette.primary.main,
                  fontWeight: 600,
                  cursor: "pointer",
                }}
                onClick={() => mutatePrivacyPolicy()}
              >
                Cookie Policy
              </span>
              .
            </Typography>

            <DialogActions
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: 0,
                mt: 2,
              }}
            >
              <Button
                variant="contained"
                type="submit"
                disabled={isLoading}
                sx={{
                  maxWidth: "500px",
                  boxShadow: "none",
                  padding: "20px 0px",
                }}
              >
                {isLoading ? (
                  <CircularProgress
                    sx={{ color: theme.palette.primary.white }}
                    size={20}
                  />
                ) : (
                  "Agree & Join"
                )}
              </Button>
            </DialogActions>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "Center",
                mt: 2,
              }}
            >
              <Typography
                variant="h2"
                sx={{ fontSize: { xs: "13px", sm: "15px" } }}
              >
                Already on Dealranker?
              </Typography>
              <Button
                variant="text"
                sx={{ fontSize: "15px" }}
                onClick={handleSignIn}
              >
                Sign In
              </Button>
            </Box>
          </Box>
        </form>
      </Dialog>
    </React.Fragment>
  );
}

import * as React from "react";
import {
  TextField,
  Box,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useFormik } from "formik";
import CancelIcon from "@mui/icons-material/Cancel";
import DialogActions from "@mui/material/DialogActions";
import { signinSchema } from "var/Schema";
import { useDispatch, useSelector } from "react-redux";
import { OpenForgotModal } from "store/slices/modalsSlice";
import { forgot, login } from "api/Auth";
import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import { userAuthenticate } from "store/slices/authSlice";

const dialogStyle = {
  "& .MuiDialog-paper": {
    width: { xs: "270px", sm: "389px" },
    maxHeight: { xs: "465px", sm: "465px", md: "465px" },
    minHeight: "250px",
    background: "#FAFAFA",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    boxRadius: "none",
    borderRadius: "8px",
  },
  "& .MuiDialog-container": {
    background: "rgba(255, 255, 255, 0.02)",
    backdropFilter: "blur(10px)",
  },
};

const dialogHeader = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: { xs: "20px 20px 20px 20px", sm: "20px 20px 20px 40px" },
};

export default function ForgotPassword() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const open = useSelector((state) => state.modals.forgot);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  // Define a mutation for login

  const { mutate, isLoading } = useMutation(forgot, {
    onSuccess: (data) => {
      dispatch(OpenForgotModal(false));
      toast.success("Password reset instructions sent to your email.");
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message || "Something went wrong!");
    },
  });

  // Formik object for managing form state and validation

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    // validationSchema: signinSchema,
    onSubmit: (values) => {
      mutate(values);
    },
  });

  const {
    values,
    handleBlur,
    handleSubmit,
    handleChange,
    touched,
    errors,
    resetForm,
  } = formik;

  const handleClose = () => {
    dispatch(OpenForgotModal(false));
    resetForm();
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        sx={dialogStyle}
        onClose={handleClose}
      >
        <Box sx={dialogHeader}>
          <CancelIcon
            sx={{ fontSize: "20px", cursor: "pointer" }}
            onClick={handleClose}
          />
        </Box>

        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              maxWidth: "300px",
              margin: "auto",
              padding: "0px 10px",
              textAlign: "center",
            }}
          >
            <Typography
              variant="h2"
              sx={{ fontSize: { xs: "13px", sm: "15px" }, mb: 2 }}
            >
              Please enter your email address to search for your account.
            </Typography>
            <TextField
              name="email"
              placeholder="Email"
              fullWidth
              value={values.email}
              onBlur={handleBlur}
              onChange={handleChange}
              sx={{
                mb: "10px",
                "& ::placeholder": {
                  textAlign: "center !important",
                  fontWeight: `${500} !important`,
                  color: `${theme.palette.primary.gray} !important`,
                  opacity: 1,
                },
              }}
              autoComplete="username"
              error={touched.email && Boolean(errors.email)}
              helperText={
                touched.email &&
                Boolean(errors.email) &&
                touched.email &&
                errors.email
              }
            />

            <DialogActions
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: 0,
                mt: 1,
              }}
            >
              <Button
                variant="contained"
                type="submit"
                disabled={isLoading}
                sx={{
                  maxWidth: "500px",
                  boxShadow: "none",
                  padding: "20px 0px",
                }}
              >
                {isLoading ? (
                  <CircularProgress
                    sx={{ color: theme.palette.primary.white }}
                    size={20}
                  />
                ) : (
                  "Search"
                )}
              </Button>
            </DialogActions>
          </Box>
        </form>
      </Dialog>
    </React.Fragment>
  );
}

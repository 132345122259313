import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { OpenIndustoryModal } from "store/slices/modalsSlice";
import { Box, List, ListItem, Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useQuery } from "react-query";
import { getIndustries } from "api/Listing";

const dialogStyle = {
  "& .MuiDialog-paper": {
    width: { xs: "270px", sm: "389px" },
    minHeight: "80vh",
    background: "#FAFAFA",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    borderRadius: "8px",
    padding: "10px",
  },
  "& .MuiDialog-container": {
    background: "rgba(255, 255, 255, 0.02)",
    backdropFilter: "blur(10px)",
  },
};

const dialogHeader = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: { xs: "20px 20px 20px 20px", sm: "20px 20px 20px 40px" },
};

export default function IndustoryModal({ setFieldValue }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const open = useSelector((state) => state.modals.industoryModal);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const auth = useSelector((state) => state.auth.auth.isAuthenticate);

  const handleClose = () => {
    dispatch(OpenIndustoryModal(false));
  };

  const [openListIndexes, setOpenListIndexes] = React.useState([]);
  const [selectedItem, setSelectedItem] = React.useState({
    name: "",
    category: "",
  });

  const handleIndustryClick = (index) => {
    setOpenListIndexes((prevIndexes) => {
      const isOpen = prevIndexes.includes(index);
      return isOpen
        ? prevIndexes.filter((prevIndex) => prevIndex !== index)
        : [...prevIndexes, index];
    });
  };

  const handleSelect = (e, item, categoryItem) => {
    e.preventDefault();
    setSelectedItem({ name: item?.industry, category: categoryItem });
    setFieldValue("industry", item?.industry);
    setFieldValue("subIndustry", categoryItem);
    dispatch(OpenIndustoryModal(false));
  };

  // Fetch listing data
  const { data } = useQuery({
    queryKey: ["getIndustries"],
    // Specifies the function that fetches the data for the query
    queryFn: () => getIndustries(),
    retry: false,
    refetchOnWindowFocus: false,
    enabled: Boolean(auth),
    notifyOnChangeProps: ["data", "error"],
  });

  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        sx={dialogStyle}
      >
        <Box sx={dialogHeader}>
          <CancelIcon
            sx={{ fontSize: "20px", cursor: "pointer" }}
            onClick={handleClose}
          />
        </Box>

        <Typography
          variant="h3"
          sx={{
            textAlign: "left",
            fontSize: { xs: "18px", sm: "22px" },
          }}
        >
          Industry
        </Typography>
        <Typography
          variant="subtitle"
          sx={{
            mb: 3,
            textAlign: "left",
            fontSize: { xs: "12px", sm: "13px" },
          }}
        >
          Select the market that best describes your buisness.
        </Typography>

        {data?.map((item, index) => (
          <Box key={index}>
            <div>
              <Box
                sx={{
                  marginBottom: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  cursor: "pointer",
                  border: `2px solid ${theme.palette.primary.main}`,
                  borderRadius: "20px",
                  padding: "0px 10px 0px 10px",
                  height: { xs: "30px", sm: "33px", md: "35px" },
                }}
                onClick={() => handleIndustryClick(index)}
              >
                <Typography variant="subtitle1">{item?.industry}</Typography>
                {openListIndexes.includes(index) ? (
                  <KeyboardArrowUpIcon
                    sx={{ fill: theme.palette.primary.main }}
                  />
                ) : (
                  <KeyboardArrowDownIcon
                    sx={{ fill: theme.palette.primary.main }}
                  />
                )}
              </Box>
              {openListIndexes.includes(index) && (
                <List>
                  {/* Render additional information for each industry */}
                  {item?.sub_industries?.map((categoryItem, i) => (
                    <ListItem
                      onClick={(e) => handleSelect(e, item, categoryItem)}
                      key={i}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                        justifyContent: "space-between",
                        backgroundColor:
                          selectedItem.category === categoryItem
                            ? "#ebebeb"
                            : "transparent", // Add a background color for the selected item
                        padding: "5px",
                        borderRadius: "5px",
                      }}
                    >
                      <Typography variant="subtitle1">
                        {categoryItem}
                      </Typography>{" "}
                      <AddIcon
                        sx={{ fill: theme.palette.primary.main, width: "18px" }}
                      />
                    </ListItem>
                  ))}
                </List>
              )}
            </div>
          </Box>
        ))}
      </Dialog>
    </React.Fragment>
  );
}

import React from "react";
import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const Loader = ({ height }) => {
  return (
    <Box
      sx={{
        // position: "absolute",
        width: "100%",
        height: height,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress size={34} />
    </Box>
  );
};

export default Loader;
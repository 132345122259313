import React from "react";
import { Box, Typography, useTheme } from "@mui/material";

const mainBox = (theme) => {
  return {
    border: `2px Solid ${theme.palette.primary.main}`,
    borderRadius: "8px",
    padding: { xs: "8px", sm: "25px", md: "32px" },
    boxShadow: "0px 5px 11.3px 0px rgba(0, 0, 0, 0.21)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    mb: 2,
  };
};

const Profile = () => {
  const theme = useTheme();

  return (
    <Box sx={mainBox(theme)}>
      <Typography variant="subtitle3">My Listing</Typography>
    </Box>
  );
};

export default Profile;

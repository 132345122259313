import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  ClickAwayListener,
  Grid,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import { editProfileSchema } from "var/Schema";
import ChangePassword from "components/modals/ChangePassword";
import ProfileAvatar from "./components/ProfileAvatar";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "react-query";
import { updateUser } from "api/Auth";
import toast from "react-hot-toast";
import { jwtDecode } from "jwt-decode";
import { userInfo } from "store/slices/authSlice";

const mainBox = (theme) => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "8px",
    padding: 1.5,
    mb: 1,
  };
};

const mainBox1 = (theme) => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "8px",
    padding: 1.5,
    mb: 1,
    width: { xs: "100%", sm: "45%", md: "45%" },
  };
};

const boxStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
};

const textFieldStyle = (theme) => {
  return {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#fff",

      "& fieldset": {
        borderColor: theme.palette.primary.gray,
        borderWidth: "2px",
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary.gray,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.primary.gray,
        borderRadius: "8px",
        outline: "none",
      },
      "&.Mui-disabled fieldset": {
        borderColor: theme.palette.primary.gray,
      },

      "& .MuiOutlinedInput-input": {
        display: "flex",
        alignItems: "center",
        padding: "0px 8px  !important",
        height: "40px",
        background: "rgba(255, 255, 255, 1)",
        color: theme.palette.primary.dark,
        overflow: "hidden",
        textOverflow: "ellipsis",
        textAlign: "left",
      },
      background: "rgba(255,255,255,0.1)",
      color: theme.palette.primary.dark,
      fontSize: "15px",
      fontWeight: 500,
      fontFamily: "inter",
      borderRadius: "8px",
      borderWidth: "2px",

      "& .MuiInputAdornment-root": {
        color: theme.palette.primary.main,
      },

      "&.Mui-focused": {
        "& .MuiOutlinedInput-input": {
          color: theme.palette.primary.dark,
          background: "transparent",
          borderRadius: "8px",
        },
        background: "transparent",
        boxShadow: "none",

        "& .MuiSvgIcon-root": {
          fill: "#fff",
        },
        "& .MuiInputAdornment-root": {
          color: "#fff",
        },
        "& ::placeholder": {
          color: `${theme.palette.primary.dark} !important`,
          textAlign: "left",
          fontWeight: `${500} `,
          letterSpacing: "-0.597px",
          opacity: 0.6,
        },
      },
      "& ::placeholder": {
        color: theme.palette.primary.dark,
        textAlign: "left ",
        fontWeight: 500,
        letterSpacing: "-0.597px",
        opacity: 0.6,
      },
    },
  };
};

function hideEmail(email) {
  // Split the email into username and domain
  const [username, domain] = email?.split("@");

  // Replace characters in the username with asterisks, showing only the first two characters
  const maskedUsername =
    username.substring(0, 2) + "*".repeat(username.length - 2);

  // Return the masked email
  return maskedUsername + "@" + domain;
}
const EditProfile = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.auth.user);
  const [edit, setEdit] = useState(false);
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);

  const { mutate, isLoading } = useMutation(updateUser, {
    onSuccess: (data) => {
      const { user_id, username, email, created_at, url, role, exp } =
        jwtDecode(data?.data?.token);
      const userData = {
        userId: user_id,
        userName: username,
        email,
        url,
        role,
        createdAt: created_at,
      };
      dispatch(userInfo(userData));
      setEdit(false);
      toast.success("Username updated successfully!");
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message || "Something went wrong!");
    },
  });

  const formik = useFormik({
    initialValues: {
      username: "",
    },
    validationSchema: editProfileSchema,
    onSubmit: (values) => {
      const data = { user: { username: values.username } };
      mutate({ data, id: user?.userId });
    },
  });

  const {
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    handleSubmit,
    setFieldValue,
  } = formik;

  const handleEditUserName = (e) => {
    e.preventDefault();
    setEdit(!edit);
  };

  const handleShowEmail = (e) => {
    e.preventDefault();
    setShow(!show);
  };

  const handleChangePassword = (e) => {
    e.preventDefault();
    setOpen(!show);
  };

  const handleClickAway = () => {
    setEdit(false);
  };

  const handleClickAwayShow = () => {
    setShow(false);
  };

  useEffect(() => {
    setFieldValue("username", user?.userName);
  }, [user]);

  return (
    <Box
      sx={{ maxWidth: "960px", margin: "auto", p: 2, pt: 5, minHeight: "50vh" }}
    >
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ProfileAvatar handleChangePassword={handleChangePassword} />
        </Grid>
        <Grid item xs={12} sm={12} md={9}>
          <Box>
            <ClickAwayListener onClickAway={handleClickAwayShow}>
              <Box sx={mainBox}>
                <Box sx={boxStyle}>
                  <Typography variant="subtitle" sx={{ mb: 0.5 }}>
                    Email:
                  </Typography>
                  <Typography variant="subtitle6">
                    {show ? user?.email : hideEmail(user?.email)}
                  </Typography>
                </Box>
                {show ? (
                  <Button
                    variant="text"
                    sx={{ fontWeight: 600, fontSize: "14px" }}
                    onClick={handleShowEmail}
                  >
                    hide
                  </Button>
                ) : (
                  <Button
                    variant="text"
                    sx={{ fontWeight: 600, fontSize: "14px" }}
                    onClick={handleShowEmail}
                  >
                    show
                  </Button>
                )}
              </Box>
            </ClickAwayListener>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                "@media (max-width: 600px)": {
                  flexDirection: "column",
                  pl: 1.5,
                  pr: 1.5,
                },
              }}
            >
              <ClickAwayListener onClickAway={handleClickAway}>
                <Box sx={mainBox1(theme)}>
                  <Box sx={boxStyle}>
                    <Typography variant="subtitle" sx={{ mb: 0.5 }}>
                      Username:
                    </Typography>
                    {!edit ? (
                      <Typography variant="subtitle6">
                        {values?.username}
                      </Typography>
                    ) : (
                      <TextField
                        name="username"
                        placeholder="Username"
                        fullWidth
                        value={values.username}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        autoComplete="off"
                        sx={textFieldStyle(theme)}
                        error={touched.username && Boolean(errors.username)}
                      />
                    )}
                  </Box>
                  {!edit ? (
                    <Button
                      variant="text"
                      sx={{ fontWeight: 600, fontSize: "14px" }}
                      onClick={
                        user?.role === "admin"
                          ? handleChangePassword
                          : handleEditUserName
                      }
                    >
                      Edit
                    </Button>
                  ) : (
                    <Button
                      variant="text"
                      sx={{ fontWeight: 600, fontSize: "14px" }}
                      onClick={handleSubmit}
                    >
                      {isLoading ? (
                        <CircularProgress
                          sx={{ color: theme.palette.primary.main }}
                          size={15}
                        />
                      ) : (
                        "save"
                      )}
                    </Button>
                  )}
                </Box>
              </ClickAwayListener>
              <Box sx={mainBox1(theme)}>
                <Box sx={boxStyle}>
                  <Typography variant="subtitle" sx={{ mb: 0.5 }}>
                    Password:
                  </Typography>
                  <Typography variant="subtitle6">********</Typography>
                </Box>
                <Button
                  variant="text"
                  sx={{ fontWeight: 600, fontSize: "14px" }}
                  onClick={handleChangePassword}
                >
                  Edit
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <ChangePassword open={open} setOpen={setOpen} />
    </Box>
  );
};

export default EditProfile;

import React from "react";
import {
  Box,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";

const textFieldStyle = {
  "& .MuiOutlinedInput-root .MuiOutlinedInput-input": {
    padding: "0px 20px 0px 0px  !important",
    textAlign: "left",
    borderRadius: "20px 0px 0px 20px !important",
  },
};

const FinancialInfo = (props) => {
  const { values, handleBlur, handleChange, touched, errors } = props.formik;

  const handleInputChange = (event) => {
    const inputValue = event.target.value;

    const numericValue = inputValue.replace(/[^0-9]/g, "");

    handleChange({
      target: {
        name: event.target.name,
        value: parseInt(numericValue, 10) || "",
      },
    });
  };

  const allowHandler = (event) => {
    const allowedKeys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
    if (!allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  };
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="askingprice"
            placeholder="Asking Price"
            fullWidth
            value={values.askingprice?.toLocaleString()}
            onBlur={handleBlur}
            onChange={handleInputChange}
            onKeyPress={allowHandler}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography
                    sx={{
                      fontFamily: "inter",
                      fontSize: "15px",
                      fontWeight: 600,
                    }}
                  >
                    $
                  </Typography>
                </InputAdornment>
              ),
            }}
            autoComplete="off"
            sx={textFieldStyle}
            error={touched.askingprice && Boolean(errors.askingprice)}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="grossrevenue"
            placeholder="Gross Revenue"
            fullWidth
            value={values.grossrevenue?.toLocaleString()}
            onBlur={handleBlur}
            onChange={handleInputChange}
            onKeyPress={allowHandler}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography
                    sx={{
                      fontFamily: "inter",
                      fontSize: "15px",
                      fontWeight: 600,
                    }}
                  >
                    $
                  </Typography>
                </InputAdornment>
              ),
            }}
            autoComplete="off"
            sx={textFieldStyle}
            error={touched.grossrevenue && Boolean(errors.grossrevenue)}
          />
          <Box sx={{ padding: "0px 15px" }}>
            <Typography variant="subtitle6" sx={{ fontSize: "12px" }}>
              What is your annual profit before expenses?
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="cashflow"
            placeholder="Cash Flow"
            //
            fullWidth
            value={values?.cashflow?.toLocaleString()}
            onBlur={handleBlur}
            onChange={handleInputChange}
            onKeyPress={allowHandler}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography
                    sx={{
                      fontFamily: "inter",
                      fontSize: "15px",
                      fontWeight: 600,
                    }}
                  >
                    $
                  </Typography>
                </InputAdornment>
              ),
            }}
            autoComplete="off"
            sx={textFieldStyle}
            error={touched.cashflow && Boolean(errors.cashflow)}
          />
          <Box sx={{ padding: "0px 15px" }}>
            <Typography variant="subtitle6" sx={{ fontSize: "12px" }}>
              The total annual amount of money being transferred into and out of
              your business.
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="EBITDA"
            placeholder="EBITDA"
            fullWidth
            value={values.EBITDA?.toLocaleString()}
            onBlur={handleBlur}
            onChange={handleInputChange}
            onKeyPress={allowHandler}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography
                    sx={{
                      fontFamily: "inter",
                      fontSize: "15px",
                      fontWeight: 600,
                    }}
                  >
                    $
                  </Typography>
                </InputAdornment>
              ),
            }}
            autoComplete="off"
            sx={textFieldStyle}
            error={touched.EBITDA && Boolean(errors.EBITDA)}
          />
          <Box sx={{ padding: "0px 15px" }}>
            <Typography variant="subtitle6" sx={{ fontSize: "12px" }}>
              The total annual amount of earnings before interest, taxes,
              depreciation and amortization.
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="realestate"
            placeholder="Real Estate"
            fullWidth
            value={values.realestate?.toLocaleString()}
            onBlur={handleBlur}
            onChange={handleInputChange}
            onKeyPress={allowHandler}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography
                    sx={{
                      fontFamily: "inter",
                      fontSize: "15px",
                      fontWeight: 600,
                    }}
                  >
                    $
                  </Typography>
                </InputAdornment>
              ),
            }}
            autoComplete="off"
            sx={textFieldStyle}
            error={touched.realestate && Boolean(errors.realestate)}
          />
          <Box sx={{ padding: "0px 15px" }}>
            <Typography variant="subtitle6" sx={{ fontSize: "12px" }}>
              Total amount of rent, include utilities, etc.
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="equipment"
            placeholder="Equipment"
            fullWidth
            value={values.equipment?.toLocaleString()}
            onBlur={handleBlur}
            onChange={handleInputChange}
            onKeyPress={allowHandler}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography
                    sx={{
                      fontFamily: "inter",
                      fontSize: "15px",
                      fontWeight: 600,
                    }}
                  >
                    $
                  </Typography>
                </InputAdornment>
              ),
            }}
            autoComplete="off"
            sx={textFieldStyle}
            error={touched.equipment && Boolean(errors.equipment)}
          />
          <Box sx={{ padding: "0px 15px" }}>
            <Typography variant="subtitle6" sx={{ fontSize: "12px" }}>
              Total amount of tools being transferred.
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="inventory"
            placeholder="Inventory"
            fullWidth
            value={values.inventory?.toLocaleString()}
            onBlur={handleBlur}
            onChange={handleInputChange}
            onKeyPress={allowHandler}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography
                    sx={{
                      fontFamily: "inter",
                      fontSize: "15px",
                      fontWeight: 600,
                    }}
                  >
                    $
                  </Typography>
                </InputAdornment>
              ),
            }}
            autoComplete="off"
            sx={textFieldStyle}
            error={touched.inventory && Boolean(errors.inventory)}
          />
          <Box sx={{ padding: "0px 15px" }}>
            <Typography variant="subtitle6" sx={{ fontSize: "12px" }}>
              Total amount of products in stock.
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="operatingexpenses"
            placeholder="Operating Expenses"
            fullWidth
            value={values.operatingexpenses?.toLocaleString()}
            onBlur={handleBlur}
            onChange={handleInputChange}
            onKeyPress={allowHandler}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography
                    sx={{
                      fontFamily: "inter",
                      fontSize: "15px",
                      fontWeight: 600,
                    }}
                  >
                    $
                  </Typography>
                </InputAdornment>
              ),
            }}
            autoComplete="off"
            sx={textFieldStyle}
            error={
              touched.operatingexpenses && Boolean(errors.operatingexpenses)
            }
          />
          <Box sx={{ padding: "0px 15px" }}>
            <Typography variant="subtitle6" sx={{ fontSize: "12px" }}>
              Registration fees, insurance, website expenses, advertisements,
              etc.
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="employees"
            placeholder="Employees"
            fullWidth
            value={values.employees?.toLocaleString()}
            onBlur={handleBlur}
            onChange={handleInputChange}
            onKeyPress={allowHandler}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography
                    sx={{
                      fontFamily: "inter",
                      fontSize: "15px",
                      fontWeight: 600,
                    }}
                  >
                    $
                  </Typography>
                </InputAdornment>
              ),
            }}
            autoComplete="off"
            sx={textFieldStyle}
            error={touched.employees && Boolean(errors.employees)}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default FinancialInfo;

import { removeEmptyValues } from "utils/utils";
import { apiIntercept } from "./Interceptors";

// get all Listing
// export const getAllListing = async (filter) => {
//   const jwt = localStorage.getItem("token");
//   const data = removeEmptyValues(filter);
//   const { data: response } = await apiIntercept.get(
//     `${process.env.REACT_APP_DEAL_RANKER_API}api/v1/companies`,
//     {
//       params: data,
//       headers: {
//         Authorization: `Bearer ${jwt}`,
//       },
//     }
//   );

//   return response.data;
// };

// get all Listing
export const getAllListing = async (filter) => {
  const jwt = localStorage.getItem("token");
  const data = removeEmptyValues(filter);
  const config = {
    params: data,
  };

  if (jwt) {
    config.headers = {
      Authorization: `Bearer ${jwt}`,
    };
  }

  const { data: response } = await apiIntercept.get(
    `${process.env.REACT_APP_DEAL_RANKER_API}api/v1/companies`,
    config
  );

  return response.data;
};

// get login user all Listing
export const getPersonalListing = async () => {
  const jwt = localStorage.getItem("token");
  const { data: response } = await apiIntercept.get(
    `${process.env.REACT_APP_DEAL_RANKER_API}api/v1/companies/user_companies`,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  );

  return response.data;
};

// get industory category list
export const getIndustries = async () => {
  const jwt = localStorage.getItem("token");
  const { data: response } = await apiIntercept.get(
    `${process.env.REACT_APP_DEAL_RANKER_API}api/v1/industries_data`,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  );

  return response.data;
};

// get industory category list
export const getIndustriesDropdown = async () => {
  const { data: response } = await apiIntercept.get(
    `${process.env.REACT_APP_DEAL_RANKER_API}api/v1/industries`
  );

  return response.data;
};

// get sate and cities list
export const getLocation = async () => {
  const jwt = localStorage.getItem("token");
  const { data: response } = await apiIntercept.get(
    `${process.env.REACT_APP_DEAL_RANKER_API}api/v1/states/locations_data`,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  );

  return response.data;
};

// get states list
export const getFilterLocation = async () => {
  const { data: response } = await apiIntercept.get(
    `${process.env.REACT_APP_DEAL_RANKER_API}api/v1/states`
  );

  return response.data;
};

// get  cities list
export const getFilterCities = async () => {
  const { data: response } = await apiIntercept.get(
    `${process.env.REACT_APP_DEAL_RANKER_API}api/v1/cities`
  );

  return response.data;
};

// add listing post req
export const addListing = async (data) => {
  const jwt = localStorage.getItem("token");
  const { data: response } = await apiIntercept.post(
    `${process.env.REACT_APP_DEAL_RANKER_API}api/v1/companies`,
    data,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  );
  return response;
};

// update listing patch req
export const updateListing = async ({ data, id }) => {
  const jwt = localStorage.getItem("token");
  const { data: response } = await apiIntercept.patch(
    `${process.env.REACT_APP_DEAL_RANKER_API}api/v1/companies/${id}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  );
  return response;
};

// delete listing  req
export const deleteList = async (id) => {
  const jwt = localStorage.getItem("token");
  const { data: response } = await apiIntercept.delete(
    `${process.env.REACT_APP_DEAL_RANKER_API}api/v1/companies/${id}`,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  );
  return response;
};

// get specific listing data req
export const getSpecificList = async (id) => {
  const jwt = localStorage.getItem("token");
  const { data: response } = await apiIntercept.get(
    `${process.env.REACT_APP_DEAL_RANKER_API}api/v1/companies/${id}`,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  );
  return response;
};

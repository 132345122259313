import axios from "axios";
import { toast } from "react-hot-toast";
import { userAuthenticate, userInfo } from "store/slices/authSlice";
import store from "store/store";

export const apiIntercept = axios.create({
  baseURL: process.env.REACT_APP_HRM_API,
});

// Add a response interceptor to handle errors
apiIntercept.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      store.dispatch(userAuthenticate(false));
      store.dispatch(userInfo(null));
      localStorage.removeItem("token");
      // toast.error("Invalid Token");
    }

    return Promise.reject(error);
  }
);

import React from "react";
import Item from "./components/Item";
import { Box, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { deleteList, getPersonalListing } from "api/Listing";
import Loader from "components/loader";
import { useNavigate } from "react-router-dom";
import DeleteModal from "components/modals/DeleteModal";
import { OpenDeleteModal, SetCommentId } from "store/slices/modalsSlice";

const boxStyle = (theme) => {
  return {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: "8px",
    background: theme.palette.primary.white,
  };
};

const PersonalList = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const deleteId = useSelector((state) => state.modals.commentsId);
  const auth = useSelector((state) => state.auth.auth.isAuthenticate);

  // Fetch listing data
  const { data, isLoading } = useQuery({
    queryKey: ["getPersonalListing"],
    // Specifies the function that fetches the data for the query
    queryFn: () => getPersonalListing(),
    retry: false,
    enabled: Boolean(auth),
    refetchOnWindowFocus: false,
    notifyOnChangeProps: ["data", "error"],
  });

  const { mutate: deleteMutate, isLoading: deleteLoading } = useMutation(
    deleteList,
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("getPersonalListing");
        queryClient.invalidateQueries("getAllListing");
        dispatch(SetCommentId(null));
        dispatch(OpenDeleteModal(false));
        toast.success("List remove successfully.");
      },
      onError: (error) => {
        toast.error(
          `${error?.response?.data?.message}` || "Something went wrong!"
        );
      },
    }
  );

  const handleDelete = () => {
    deleteMutate(deleteId);
  };

  return (
    <Box sx={{ mb: 4 }}>
      {isLoading ? (
        <Box sx={boxStyle(theme)}>
          <Loader height={200} />
        </Box>
      ) : (!isLoading && !data) || !data.length ? null : (
        data &&
        data?.map((value, index) => (
          <Box key={index} sx={{ mb: 1 }}>
            <Item data={value} />
          </Box>
        ))
      )}
      <DeleteModal handleDelete={handleDelete} />
    </Box>
  );
};

export default PersonalList;

import React, { useRef, useState } from "react";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { Avatar, Box, Typography, useTheme } from "@mui/material";

const imageStyle = (theme) => {
  return {
    p: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    borderRadius: "50%",
    border: `1px solid  ${theme.palette.primary.main}`,
    background: "",
    height: "100%",
    width: "100%",
  };
};

const imageIcons = {
  width: "120px",
  borderRadius: "50%",
  height: "120px",
};

const UploadAssets = ({ setImage, image, formik }) => {
  const { setFieldValue, values } = formik;
  const theme = useTheme();
  const fileInputRef = useRef(null);
  const [hovered, setHovered] = useState(false);

  const handleHover = () => {
    setHovered(!hovered);
  };

  const handleImageUpload = async (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    setFieldValue("logo", file);
    setImage(URL.createObjectURL(file));
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        flexDirection: "column",
        mb: 2,
      }}
    >
      <Box
        onMouseEnter={handleHover}
        onMouseLeave={handleHover}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          width: "120px",
          height: "120px",
          borderRadius: "50%",
        }}
      >
        <Box
          sx={imageStyle(theme)}
          onClick={() => {
            fileInputRef.current.click();
          }}
        >
          {image || values?.logo ? (
            <img src={image || values?.logo} style={imageIcons} alt="avatar" />
          ) : (
            <Avatar src="/broken-image.jpg" sx={imageIcons} />
          )}
        </Box>

        <input
          type="file"
          ref={fileInputRef}
          accept="image/jpeg, image/png"
          onChange={handleImageUpload}
          style={{ display: "none" }}
        />
        {hovered && (
          <IconButton
            component="label"
            htmlFor="upload-image"
            sx={{
              position: "absolute",
            }}
            onClick={() => fileInputRef.current.click()}
          >
            <PhotoCamera />
          </IconButton>
        )}
      </Box>

      <Typography variant="subtitle1" sx={{ mt: 2 }}>
        Upload Logo
      </Typography>
    </Box>
  );
};

export default UploadAssets;

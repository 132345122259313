import { useTheme } from "@emotion/react";
import { Avatar, Box, Button, Grid, Stack, Typography } from "@mui/material";
import { rankUpReq } from "api/Comments";
import { ArrowDownIcon, ArrowUpIcon } from "assets/images";
import CommentsModal from "components/modals/CommentsModal";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { OpenLoginModal, SetCommentId } from "store/slices/modalsSlice";

const rankBox = (theme) => {
  return {
    display: "flex",
    alignItems: "center",
    background: theme.palette.primary.main,
    height: "35px",
    borderRadius: "20px",
    "@media (max-width: 600px)": {
      mt: "0px",
      ml: "0px",
      width: "100%",
    },
  };
};
const rankButton = {
  fontSize: { xs: "10px", sm: "12px", md: "14px" },
  maxWidth: "100px",
  height: "27px",
  "@media (max-width: 600px)": {
    maxWidth: "100%",
  },
};

const visitButton = {
  fontSize: { xs: "10px", sm: "12px", md: "14px" },
  width: "120px",
  height: "27px",
  mr: 2,
  "@media (max-width: 600px)": {
    maxWidth: "100%",
  },
};

const rankIconStyle = {
  width: { xs: "12px", sm: "15px", md: "18px" },
  display: "flex",
  alignItems: "center",
};

const rankTextStyle = (theme) => {
  return {
    fontSize: { xs: "10px", sm: "12px", md: "15px" },
    margin: "0px 10px",
    color: theme.palette.primary.white,
  };
};

const Header = ({ data, id }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth.auth.isAuthenticate);

  const [rankState, setRankState] = useState({
    rankUp: data?.company?.rank_up_count,
    rankDown: data?.company?.rank_down_count,
    rankUpDisable: data?.user_reaction === "rank_up" ? true : false,
    rankDownDisable: data?.user_reaction === "rank_down" ? true : false,
    noRank: data?.user_reaction === "neutral" ? true : false,
  });
  const [openModal, setOpenModal] = useState(false);

  const openModalHandler = () => {
    setOpenModal(true);
  };

  // rank up req
  const { mutate: rankUpMutate, isLoading: rankUpLoading } = useMutation(
    rankUpReq,
    {
      onSuccess: () => {},
      onError: (error) => {
        toast.error(error?.response?.data?.message || "Something went wrong!");
      },
    }
  );

  const handleRankUp = (e, id) => {
    e.preventDefault();
    if (rankState?.noRank === false) {
      setRankState((prevState) => ({
        ...prevState,
        rankUp: prevState?.rankUp + 1,
        rankDown: prevState?.rankDown === 0 ? 0 : prevState?.rankDown - 1,
        rankUpDisable: true,
        rankDownDisable: false,
        noRank: false,
      }));
      rankUpMutate(id);
    } else {
      setRankState((prevState) => ({
        ...prevState,
        rankUp: prevState?.rankUp + 1,
        rankUpDisable: true,
        rankDownDisable: false,
        noRank: false,
      }));
      rankUpMutate(id);
    }
  };

  const handleCommentCount = () => {
    if (rankState?.noRank === false) {
      setRankState((prevState) => ({
        ...prevState,
        rankUp: prevState?.rankUp === 0 ? 0 : prevState?.rankUp - 1,
        rankDown: prevState?.rankDown + 1,
        rankUpDisable: false,
        rankDownDisable: true,
        noRank: false,
      }));
    } else {
      setRankState((prevState) => ({
        ...prevState,
        rankDown: prevState?.rankDown + 1,
        rankUpDisable: false,
        rankDownDisable: true,
        noRank: false,
      }));
    }
  };

  const handleRankDown = (e, id) => {
    e.preventDefault();
    if (auth) {
      dispatch(SetCommentId(id));
      openModalHandler(true);
    } else {
      dispatch(OpenLoginModal(true));
    }
  };

  const openLink = (link) => {
    // Check if the link starts with a protocol, if not, prepend 'https://'
    if (!link.startsWith("http://") && !link.startsWith("https://")) {
      link = "https://" + link;
    }

    // Open the link in a new tab
    window.open(link, "_blank");
  };
  return (
    <Box sx={{ maxWidth: "960px", margin: "auto", pb: 3 }}>
      <Box sx={{ mb: 2 }}>
        <Stack direction="row" spacing={1}>
          <Avatar
            variant="square"
            src={data?.company?.logo || "/broken-image.jpg"}
            sx={{
              width: "50px",
              height: "50px",
              borderRadius: "12px",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            }}
          />
        </Stack>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "space-between",
          width: "100%",
          "@media (max-width: 600px)": {
            flexDirection: "column",
            alignItems: "flex-start",
          },
        }}
      >
        <Box>
          <Typography
            variant="subtitle"
            sx={{
              fontSize: { xs: "9px", sm: "12px", md: "12px" },
              fontWeight: 400,
              mb: 0.5,
            }}
          >
            {data?.company?.created_at &&
              dayjs(data?.company?.created_at).format("MMMM DD, YYYY")}
          </Typography>
          <Typography variant="h2" sx={{ textAlign: "left", mb: 0.5 }}>
            {data?.company?.name}
          </Typography>
          <Typography
            variant="subtitle6"
            sx={{ fontSize: "18px", fontWeight: 500 }}
          >
            {data?.company?.industry} , {data?.company?.sub_industry}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "inline-flex",
            alignItems: "center",
            "@media (max-width: 600px)": {
              width: "100%",
              mt: 2,
            },
          }}
        >
          {data?.company?.website && (
            <Button
              variant="contained"
              onClick={() => openLink(data?.company?.website)}
              sx={visitButton}
            >
              Visit Website
            </Button>
          )}
          <Box sx={rankBox(theme)}>
            <Button
              variant="contained"
              onClick={(e) => handleRankUp(e, id)}
              sx={rankButton}
              disabled={rankState?.rankUpDisable}
              startIcon={
                <Box sx={rankIconStyle}>
                  <img src={ArrowUpIcon} alt="stats icon" width="100%" />
                </Box>
              }
            >
              {rankState?.rankUp}
            </Button>
            <Typography variant="subtitle" sx={rankTextStyle(theme)}>
              Rank
            </Typography>
            <Button
              variant="contained"
              onClick={(e) => handleRankDown(e, id)}
              sx={rankButton}
              disabled={rankState?.rankDownDisable}
              startIcon={
                <Box sx={rankIconStyle}>
                  <img src={ArrowDownIcon} alt="stats icon" width="18px" />
                </Box>
              }
            >
              {rankState?.rankDown}
            </Button>
          </Box>
        </Box>
      </Box>

      <Box sx={{ mt: 5 }}>
        <Typography
          variant="subtitle5"
          sx={{ textAlign: "left" }}
          dangerouslySetInnerHTML={{ __html: data?.company?.description }}
        />
      </Box>

      <CommentsModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        handleCommentCount={handleCommentCount}
      />
    </Box>
  );
};

export default Header;

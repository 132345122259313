import { red, blueGrey, grey } from "@mui/material/colors";

const blue = "#1D80EE";
const white = "#ffff";
const black = "#000";
const redShade = "#EE1D1D";
const gray ="#949494";
const steelBlue="#4C6C8F"


export const palette = {
  black,
  white,
  blue,
  gray,

  primary: {
    main: blue,
    dark: black,
    white,
    red:redShade,
    gray
   
  },
  secondary:{
    main: steelBlue,
  },
  error: {
    contrastText: white,
    dark: red[900],
    main: red[600],
    light: red[400],
  },
  link: blue[800],
  icon: blueGrey[600],
  background: {
    default: white,
    paper: white,
  },
  divider: grey[200],
};
import { apiIntercept } from "./Interceptors";
import { subscriptionSchema } from "../var/Schema";

// get FAQS array
export const getFAQS = async () => {
  const { data: response } = await apiIntercept.get(
    `${process.env.REACT_APP_DEAL_RANKER_API}api/v1/faqs`
  );

  return response.data;
};

// get disclaimer array
export const getDisclaimer = async () => {
  const { data: response } = await apiIntercept.get(
    `${process.env.REACT_APP_DEAL_RANKER_API}api/v1/disclaimers`
  );

  return response.data;
};

// get privacy policy
export const getPrivacyPolicy = async () => {
  const { data: response } = await apiIntercept.get(
    `${process.env.REACT_APP_DEAL_RANKER_API}api/v1/privacy_policy_file`
  );

  return response.data;
};

// subscribe users
export const subscribeUser = async (data) => {
  const { data: response } = await apiIntercept.post(
    `${process.env.REACT_APP_DEAL_RANKER_API}api/v1/subscribers`,
    data
  );

  return response.data;
};

import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, useTheme } from "@mui/material";
import { DownloadIcon } from "assets/images";
import { useMutation, useQuery } from "react-query";
import { getDisclaimer, getFAQS, getPrivacyPolicy } from "api/FAQS";
import toast from "react-hot-toast";

const mainBox = {
  maxWidth: { xs: "90%", sm: "80%", md: "70%" },
  margin: "60px auto",
};

const accordianStyle = {
  background: "transparent",
  boxShadow: "none",
  textAlign: "left",
  "&.MuiAccordion-root:before": {
    height: "0px !important",
  },
};

const accordionHeading = {
  textAlign: "left",
  fontSize: { xs: "18px", sm: "22px", md: "28px" },
};

const accordionText = {
  textAlign: "left",
  fontSize: { xs: "15px", sm: "15pxpx", md: "16px" },
};

const accordionIcon = (theme) => {
  return {
    color: theme.palette.primary.white,
    width: { xs: "30px", sm: "35px", md: "40px" },
    height: { xs: "30px", sm: "35px", md: "40px" },
  };
};

const array = [
  {
    title: "How we use your information",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
  },
  {
    title: "How we collect your information",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
  },
  {
    title: "How to remove your information",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
  },
];

const renderHTML = (htmlString) => {
  return { __html: htmlString };
};

export default function Accordon() {
  const theme = useTheme();

  // Fetch listing data
  const { data, isLoading } = useQuery({
    queryKey: ["getFAQS"],
    // Specifies the function that fetches the data for the query
    queryFn: () => getFAQS(),
    retry: false,
    refetchOnWindowFocus: false,
    notifyOnChangeProps: ["data", "error"],
  });

  // Fetch listing data
  const { data: disclaimerData, isLoading: disclaimerLoading } = useQuery({
    queryKey: ["getDisclaimer"],
    // Specifies the function that fetches the data for the query
    queryFn: () => getDisclaimer(),
    retry: false,
    refetchOnWindowFocus: false,
    notifyOnChangeProps: ["data", "error"],
  });

  // Define a mutation for privacy
  const { mutate: mutatePrivacyPolicy, isLoading: getPrivacyPolicyLoading } =
    useMutation(getPrivacyPolicy, {
      onSuccess: (data) => {
        window.open(data, "_blank");
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message || "Something went wrong!");
      },
    });

  return (
    <Box sx={mainBox}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mb: "20px",
          cursor: "pointer",
        }}
        onClick={() => mutatePrivacyPolicy()}
      >
        <Box sx={{ width: "60px", padding: "0px 15px" }}>
          <img src={DownloadIcon} alt="download button" width="100%" />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            cursor: "pointer",
          }}
          onClick={() => mutatePrivacyPolicy()}
        >
          <Typography
            variant="subtitle3"
            sx={{ fontSize: { xs: "26px", sm: "28px", md: "28px" } }}
          >
            Privacy Policy
          </Typography>
          <Typography
            variant="subtitle3"
            sx={{
              fontSize: { xs: "17px", sm: "20px", md: "20px" },
              textAlign: "left",
            }}
          >
            Download all your information
          </Typography>
        </Box>
      </Box>

      {data &&
        data?.map((item, index) => (
          <Accordion sx={accordianStyle} key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={accordionIcon(theme)} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="subtitle3" sx={accordionHeading}>
                {item?.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="subtitle4"
                sx={accordionText}
                dangerouslySetInnerHTML={renderHTML(item?.description)}
              />
            </AccordionDetails>
          </Accordion>
        ))}

      <Box sx={{ padding: "30px 15px", textAlign: "left" }}>
        <Typography variant="subtitle4" sx={{ fontWeight: 400 }}>
          {disclaimerData && disclaimerData?.[0]?.description}
        </Typography>
      </Box>

      <Box sx={{ padding: "0px 15px 15px 15px", textAlign: "left" }}>
        <Typography variant="subtitle4" sx={{ fontWeight: 400 }}>
          Partnership ideas email us at{" "}
          <a href="mailto:info@dealranker.com" style={{ color: "#4FA2FF" }}>
            info@dealranker.com
          </a>
        </Typography>
      </Box>
    </Box>
  );
}

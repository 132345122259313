import { Box, ThemeProvider } from "@mui/material";
import { Toaster } from "react-hot-toast";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "routes";
import lightTheme from "./theme";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useState } from "react";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";

const queryClient = new QueryClient();

function App() {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const threshold = 200; // You can adjust this value based on when you want the button to appear

      setShowButton(scrollY > threshold);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Toaster
        toastOptions={{
          style: {
            overflowWrap: "break-word",
            maxWidth: "600px",
          },
        }}
      />
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={lightTheme}>
          <BrowserRouter>
            <AppRoutes />
            {showButton && (
              <Box
                onClick={scrollToTop}
                style={{
                  position: "fixed",
                  bottom: "20px",
                  left: "20px",
                  borderRadius: "50%",
                  background: "transparent",
                  cursor: "pointer",
                }}
              >
                <ArrowCircleUpIcon
                  sx={{
                    fill: "#1D80EE",
                    width: { xs: "30px", sm: "60px" },
                    height: { xs: "30px", sm: "60px" },
                  }}
                />
              </Box>
            )}
          </BrowserRouter>
        </ThemeProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;

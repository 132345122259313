/**
 * Capitalize the first letter of a string.
 * @param {string} s - The input string.
 * @returns {string} - The string with the first letter capitalized.
 */
export function capitalizeFirstLetter(s) {
  if (s !== undefined || s !== null || s !== "") {
    return s?.charAt(0)?.toUpperCase() + s?.slice(1);
  }
}

export const getYearsArray = (startYear, endYear) => {
  const years = [];
  for (let year = startYear; year <= endYear; year++) {
    years.push(year.toString());
  }
  return years;
};

export function removeEmptyValues(obj) {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }
  const newObj = { ...obj };

  for (let key in newObj) {
    // Check and remove empty values
    if (
      newObj[key] === null ||
      newObj[key] === undefined ||
      newObj[key] === "" ||
      newObj[key] === "All" ||
      (typeof newObj[key] === "object" && Object.keys(newObj[key]).length === 0)
    ) {
      delete newObj[key];
    } else if (typeof newObj[key] === "object") {
      newObj[key] = removeEmptyValues(newObj[key]);
    }
  }

  return newObj;
}

export const reasonsList = [
  "Retirement",
  "Exit Plan",
  "White Label",
  "Bankruptcy",
];
export const lifeStyleList = [
  "Startup",
  "Solo",
  "Mid-Size",
  "Small-Business",
  "Owner-Operator",
  "Corporation",
  "Franchise",
  "Non-profit",
  "Remote",
  "Manual Labor",
];
export const timeFrameList = [
  "1-3 months",
  "3-6 months",
  "6-12 months",
  "12+ months",
];
export const employeeList = ["Owner", "Employee"];

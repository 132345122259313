import React, { useState } from "react";
import {
  Button,
  Typography,
  Collapse,
  Box,
  Stack,
  Avatar,
  CircularProgress,
  useTheme,
  TextField,
  InputAdornment,
} from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  commentReply,
  getComments,
  getReplies,
  postComments,
} from "../../../../../api/Comments";
import SendIcon from "@mui/icons-material/Send";
import { useFormik } from "formik";
import dayjs from "dayjs";
import NoData from "components/nodata";
import Loader from "components/loader";
import { commentSchema } from "var/Schema";
import { useNavigate } from "react-router";

const textFieldStyle = () => {
  return {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-input": {
      padding: "0px 0px 0px 15px  !important",
      textAlign: "left",
      borderRadius: "20px 0px 0px 20px !important",
      fontSize: "13px",
      height: "36px",
    },
  };
};

const Comment = ({ data, companyId, openReplyId, setOpenReplyId }) => {
  const theme = useTheme();
  const [showReplies, setShowReplies] = useState(false);
  const [typeReply, setTypeReply] = useState(false);
  const [replies, setReplies] = useState([]);
  const parentId = data?.comment?.id;

  // Formik object for managing form state and validation

  const formik = useFormik({
    initialValues: {
      reply: "",
    },
    onSubmit: (values) => {
      if (values.reply === "") {
        return;
      }
      mutate({
        parentId,
        companyId,
        data: {
          body: values?.reply,
        },
      });
    },
  });

  const { values, handleSubmit, handleChange, resetForm } = formik;

  // Fetch replies data using React Query
  const { data: response, isLoading: isLoadingReplies } = useQuery({
    queryKey: ["getReplies", parentId, companyId],
    queryFn: () => getReplies(parentId, companyId),
    enabled: showReplies,
    retry: false,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    notifyOnChangeProps: ["data", "error"],
    onSuccess: (data) => {
      // Update local state with fetched comments
      setReplies(data || []);
    },
  });

  // Define a mutation for login
  const { mutate, isLoading } = useMutation(commentReply, {
    onSuccess: (data) => {
      setReplies([...replies, data?.data]);
      resetForm();
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message || "Something went wrong!");
    },
  });

  const toggleReplies = () => {
    setShowReplies(!showReplies);
  };

  const addReplies = () => {
    setShowReplies(true);
    setTypeReply(true);
    setOpenReplyId(parentId);
  };

  return (
    <Box
      style={{
        margin: "10px",
        padding: "10px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <Stack direction="row" spacing={1}>
        <Avatar
          src={data?.user?.profile_image || "/broken-image.jpg"}
          sx={{
            width: { xs: "20px", sm: "40px", md: "40px" },
            height: { xs: "20px", sm: "40px", md: "40px" },
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Typography
            variant="subtitle"
            sx={{ fontSize: { xs: "9px", sm: "16px", md: "20px" } }}
          >
            {data?.user?.username}
          </Typography>
          <Typography
            variant="subtitle"
            sx={{
              fontSize: { xs: "9px", sm: "12px", md: "12px" },
              fontWeight: 400,
            }}
          >
            Member since:&nbsp;
            {data?.user?.created_at &&
              dayjs(data?.user?.created_at).format("MMM DD, YYYY")}
          </Typography>
        </Box>
      </Stack>

      <Typography
        variant="subtitle5"
        sx={{ textAlign: "left", fontWeight: 400, fontSize: "14px", mt: 1 }}
      >
        {data?.comment?.body}
      </Typography>

      <Box>
        <Button
          variant="text"
          sx={{ fontSize: "13px", justifyContent: "flex-start" }}
          onClick={addReplies}
        >
          Reply
        </Button>
        {data?.has_replies && !showReplies && (
          <Button
            variant="text"
            sx={{ fontSize: "13px" }}
            onClick={toggleReplies}
            disabled={isLoadingReplies}
          >
            {isLoadingReplies ? (
              <CircularProgress
                sx={{ color: theme.palette.primary.main }}
                size={12}
              />
            ) : (
              "View Replies"
            )}
          </Button>
        )}
      </Box>

      <Collapse in={showReplies} sx={{ width: "100%" }}>
        <div style={{ marginLeft: "20px", marginTop: "10px" }}>
          {replies &&
            replies?.map((item, index) => (
              <Comment
                key={index}
                data={item}
                companyId={companyId}
                openReplyId={openReplyId}
                setOpenReplyId={setOpenReplyId}
              />
            ))}

          {typeReply && openReplyId === parentId && (
            <TextField
              name="reply"
              placeholder="Reply"
              fullWidth
              value={values.reply}
              onChange={handleChange}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault(); // Prevents the default behavior of the Enter key (e.g., adding a new line)
                  handleSubmit();
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SendIcon
                      disabled={isLoading}
                      sx={{ fontSize: "1.2rem", cursor: "pointer" }}
                      onClick={handleSubmit}
                    />
                  </InputAdornment>
                ),
              }}
              autoComplete="off"
              sx={textFieldStyle}
            />
          )}
        </div>
      </Collapse>
    </Box>
  );
};

const Comments = ({ id }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [commentArray, setCommentArray] = useState([]);
  const [openReplyId, setOpenReplyId] = useState(null);

  const { data, isLoading } = useQuery({
    queryKey: ["getsComments", id],
    queryFn: () => getComments(id),
    retry: false,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    notifyOnChangeProps: ["data", "error"],
    onSuccess: (data) => {
      // Update local state with fetched comments
      setCommentArray(data?.comments_data || []);
    },
  });

  const { mutate, isLoading: commentLoading } = useMutation(postComments, {
    onSuccess: (data) => {
      setCommentArray([...commentArray, data]);
      queryClient.invalidateQueries("getAllListing");
      resetForm();
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message || "Something went wrong!");
    },
  });

  // Formik object for managing form state and validation
  const formik = useFormik({
    initialValues: {
      comment: "",
    },
    validationSchema: commentSchema,
    onSubmit: (values) => {
      mutate({
        id: id,
        data: {
          body: values.comment,
        },
      });
    },
  });

  const { values, handleSubmit, handleChange, resetForm } = formik;

  const handleSeeMore = (e, id) => {
    e.preventDefault();
    navigate(`/view-listing/${id}`);
  };

  return (
    <div>
      {isLoading ? (
        <Loader height={150} />
      ) : (!isLoading && !commentArray) || !commentArray.length ? (
        <NoData height={150} text="No comment found!" />
      ) : (
        commentArray &&
        commentArray?.map((data, index) => (
          <Comment
            key={index}
            data={data}
            companyId={id}
            openReplyId={openReplyId}
            setOpenReplyId={setOpenReplyId}
          />
        ))
      )}
      {data?.total_comments_count > 2 && (
        <Button
          variant="text"
          sx={{
            maxWidth: "130px",
            mt: 2,
            fontSize: "14px",
            textDecoration: "underline",
          }}
          onClick={(e) => handleSeeMore(e, id)}
        >
          See more
        </Button>
      )}
      <TextField
        name="comment"
        placeholder="Enter comment..."
        fullWidth
        value={values.comment}
        onChange={handleChange}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault(); // Prevents the default behavior of the Enter key (e.g., adding a new line)
            handleSubmit();
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {commentLoading ? (
                <CircularProgress size={12} />
              ) : (
                <SendIcon
                  disabled={commentLoading}
                  sx={{ fontSize: "1.2rem", cursor: "pointer" }}
                  onClick={handleSubmit}
                />
              )}
            </InputAdornment>
          ),
        }}
        autoComplete="new_password"
        sx={textFieldStyle}
      />
    </div>
  );
};

export default Comments;

import * as yup from "yup";

export const signupSchema = yup.object({
  username: yup.string().required("User name is required"),
  email: yup
    .string()
    .email("Enter correct email")
    .required("Email is required"),
  password: yup.string().min(8).required("Password is required"),
});

export const signinSchema = yup.object({
  email: yup.string().required("Email/Username is required"),
  password: yup.string().min(8).required("Password is required"),
});

export const subscriptionSchema = yup.object({
  email: yup
    .string()
    .email("Enter correct email")
    .required("Email is required"),
});

export const commentSchema = yup.object({
  comment: yup.string().required(),
});

export const replySchema = yup.object({
  reply: yup.string().required(),
});

export const editProfileSchema = yup.object({
  username: yup.string().required(),
});

export const changePasswordSchema = yup.object({
  password: yup.string().required("Current password is required"),

  newPassword: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),

  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword")], "Both password need to be the same")
    .required("Confirm password is required"),
});

export const addListingSchema = yup.object({
  name: yup.string().required("Required"),
  owner: yup.string().required("Required"),
  industry: yup.string().required("Required"),
  // zipcode: yup.number().required("Required"),
  city: yup.string().required("Required"),
  state: yup.string().required("Required"),
  // lifestyle: yup.string().required("Required"),
  // reason: yup.string().required("Required"),
  askingprice: yup.number().required("Required"),
  grossrevenue: yup.number().required("Required"),
  cashflow: yup.number().required("Required"),
  // EBITDA: yup.number().required("Required"),
  // realestate: yup.number().required("Required"),
  // equipment: yup.number().required("Required"),
  // inventory: yup.number().required("Required"),
  // operatingexpenses: yup.number().required("Required"),
  // employees: yup.number().required("Required"),
  // clients: yup.number().required("Required"),
  // socialmedia: yup.number().required("Required"),
  // emaillist: yup.number().required("Required"),
  // website: yup.string().required("Required"),
  description: yup.string().required("Required"),
  // swot: yup.string().required("Required"),
  // marketing: yup.string().required("Required"),
  // training: yup.string().required("Required"),
  // assets: yup.string().required("Required"),
  // wruselling: yup.string().required("Required"),
});

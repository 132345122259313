import {
  Avatar,
  Box,
  Button,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  Car,
  LocationIcon,
  ShareIcon,
} from "assets/images";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { capitalizeFirstLetter } from "utils/utils";
import Stats from "./Stats";
import copy from "copy-to-clipboard";
import Comments from "./comments";
import { useMutation, useQueryClient } from "react-query";
import { rankDownReq, rankUpReq } from "api/Comments";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { OpenLoginModal, SetCommentId } from "store/slices/modalsSlice";
import CommentsModal from "components/modals/CommentsModal";
import { useNavigate } from "react-router";

const mainBox = (theme) => {
  return {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: "8px",
    padding: 1,
    boxShadow: "0px 5px 11.3px 0px rgba(0, 0, 0, 0.21)",
    mb: 4,
  };
};

const sectionHeader = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  mb: 1,
};

const filterInfoStyle = {
  display: "flex",
  alignItems: "center",
  mr: { xs: "8px", sm: "12px", md: "15px" },
};

const textStyle = {
  ml: { xs: "4px", sm: "4", md: "5px" },
  fontSize: { xs: "9px", sm: "12px", md: "15px" },
};

const mainText = {
  fontSize: { xs: "16px", sm: "25px", md: "32px" },
  textAlign: "left",
  width: "100%",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const shareButton = {
  fontSize: { xs: "10px", sm: "12px", md: "14px" },
  maxWidth: "90px",
  height: "23px",
  ml: 1.5,
  "& .MuiButton-endIcon": { ml: { xs: "4px", sm: "6px", md: "8px" } },
  "@media (max-width: 440px)": {
    mt: "12px",
    ml: "0px",
    maxWidth: "100%",
  },
};

const shareIconStyle = {
  width: { xs: "10px", sm: "12px", md: "12px" },
  display: "flex",
  alignItems: "center",
};

const statsButton = (theme, value) => {
  return {
    fontSize: { xs: "10px", sm: "12px", md: "14px" },
    maxWidth: "85px",
    height: "23px",
    ml: 1.5,
    // color: value ? theme.palette.primary.main : "#fff",
    // backgroundColor: value ? "#fff" : theme.palette.primary.main,
    "& .MuiButton-endIcon": { ml: { xs: "4px", sm: "6px", md: "8px" } },
    "@media (max-width: 440px)": {
      mt: "12px",
      ml: "0px",
      maxWidth: "100%",
    },
  };
};

const statsIconStyle = {
  width: { xs: "10px", sm: "12px", md: "13px" },
  display: "flex",
  alignItems: "center",
};

const commentsButton = (theme, showComments) => {
  return {
    fontSize: { xs: "10px", sm: "12px", md: "14px" },
    maxWidth: "130px",
    height: "23px",
    ml: 1.5,
    minWidth: "100px",
    // color: showComments ? theme.palette.primary.main : "#fff",
    // backgroundColor: showComments ? "#fff" : theme.palette.primary.main,
    "@media (max-width: 440px)": {
      mt: "12px",
      ml: "0px",
      maxWidth: "100%",
    },
  };
};

const rankBox = (theme) => {
  return {
    display: "flex",
    alignItems: "center",
    background: theme.palette.primary.main,
    height: "31px",
    borderRadius: "20px",
    "@media (max-width: 440px)": {
      mt: "8px",
      ml: "0px",
      width: "100%",
    },
  };
};

const rankButton = {
  fontSize: { xs: "10px", sm: "12px", md: "14px" },
  maxWidth: "80px",
  height: "23px",
  "@media (max-width: 440px)": {
    maxWidth: "100%",
  },
};

const rankIconStyle = {
  width: { xs: "12px", sm: "15px", md: "18px" },
  display: "flex",
  alignItems: "center",
};

const rankTextStyle = (theme) => {
  return {
    fontSize: { xs: "10px", sm: "12px", md: "15px" },
    margin: "0px 10px",
    color: theme.palette.primary.white,
  };
};

const Item = ({ data, index }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth.auth.isAuthenticate);
  const [value, setValue] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [showComments, setShowComments] = React.useState(false);
  const [rankState, setRankState] = React.useState({
    rankUp: data?.company?.rank_up_count,
    rankDown: data?.company?.rank_down_count,
    rankUpDisable: data?.user_reaction === "rank_up" ? true : false,
    rankDownDisable: data?.user_reaction === "rank_down" ? true : false,
    noRank: data?.user_reaction === "neutral" ? true : false,
  });

  const [openModal, setOpenModal] = useState(false);

  const openModalHandler = () => {
    setOpenModal(true);
  };

  const handleChange = (e) => {
    e.preventDefault();
    setShowComments(false);
    setValue(!value);
  };

  const handleShowComments = (e) => {
    e.preventDefault();
    if (auth) {
      setValue(false);
      setShowComments(!showComments);
    } else {
      dispatch(OpenLoginModal(true));
    }
  };

  const handleCopy = (e, companyId) => {
    e.preventDefault();
    setOpen(true);
    copy(`${window.location.origin}/company-listings/${companyId}`);
    setTimeout(() => {
      setOpen(false);
    }, 1000);
  };

  // rank up req
  const { mutate: rankUpMutate, isLoading: rankUpLoading } = useMutation(
    rankUpReq,
    {
      onSuccess: () => {},
      onError: (error) => {
        toast.error(error?.response?.data?.message || "Something went wrong!");
      },
    }
  );

  const handleRankUp = (e, id) => {
    e.preventDefault();
    setValue(false);
    setShowComments(false);
    if (auth) {
      if (rankState?.noRank === false) {
        setRankState((prevState) => ({
          ...prevState,
          rankUp: prevState?.rankUp + 1,
          rankDown: prevState?.rankDown === 0 ? 0 : prevState?.rankDown - 1,
          rankUpDisable: true,
          rankDownDisable: false,
          noRank: false,
        }));
        rankUpMutate(id);
      } else {
        setRankState((prevState) => ({
          ...prevState,
          rankUp: prevState?.rankUp + 1,
          rankUpDisable: true,
          rankDownDisable: false,
          noRank: false,
        }));
        rankUpMutate(id);
      }
    } else {
      dispatch(OpenLoginModal(true));
    }
  };

  const handleCommentCount = () => {
    if (rankState?.noRank === false) {
      setRankState((prevState) => ({
        ...prevState,
        rankUp: prevState?.rankUp === 0 ? 0 : prevState?.rankUp - 1,
        rankDown: prevState?.rankDown + 1,
        rankUpDisable: false,
        rankDownDisable: true,
        noRank: false,
      }));
    } else {
      setRankState((prevState) => ({
        ...prevState,
        rankDown: prevState?.rankDown + 1,
        rankUpDisable: false,
        rankDownDisable: true,
        noRank: false,
      }));
    }
  };

  const handleRankDown = (e, id) => {
    e.preventDefault();
    setValue(false);
    setShowComments(false);
    if (auth) {
      dispatch(SetCommentId(id));
      openModalHandler(true);
    } else {
      dispatch(OpenLoginModal(true));
    }
  };

  const handleCompanyDetail = (e, id) => {
    e.preventDefault();
    // if (auth) {
    navigate(`/view-listing/${id}`);
    // } else {
    //   dispatch(OpenLoginModal(true));
    // }
  };

  return (
    <Box sx={mainBox(theme)}>
      <Box sx={sectionHeader}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={filterInfoStyle}>
            <Typography variant="subtitle" sx={textStyle}>
              {capitalizeFirstLetter(data?.company?.industry)}
            </Typography>
          </Box>

          <Box sx={filterInfoStyle}>
            <Box
              sx={{
                width: { xs: "6px", sm: "9px", md: "9px" },
                display: "flex",
                alignItems: "center",
              }}
            >
              <img src={LocationIcon} alt="Location icon" width="100%" />
            </Box>
            <Typography variant="subtitle" sx={textStyle}>
              {capitalizeFirstLetter(data?.company?.city)} (
              {capitalizeFirstLetter(data?.company?.state)})
            </Typography>
          </Box>

          <Box sx={filterInfoStyle}>
            <Typography variant="subtitle" sx={textStyle}>
              $&nbsp;{data?.company?.asking_price?.toLocaleString()}
            </Typography>
          </Box>
        </Box>

        <Stack direction="row" spacing={1}>
          <Avatar
            src={data?.user?.profile_image || "/broken-image.jpg"}
            sx={{
              width: { xs: "20px", sm: "30px", md: "30px" },
              height: { xs: "20px", sm: "30px", md: "30px" },
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography
              variant="subtitle"
              sx={{ fontSize: { xs: "9px", sm: "12px", md: "12px" } }}
            >
              {data?.user?.username}
            </Typography>
            <Typography
              variant="subtitle"
              sx={{
                fontSize: { xs: "9px", sm: "12px", md: "12px" },
                fontWeight: 400,
              }}
            >
              {data?.company?.created_at &&
                dayjs(data?.company?.created_at).format("MMMM DD, YYYY")}
            </Typography>
          </Box>
        </Stack>
      </Box>
      <Box
        sx={{ maxWidth: "900px", cursor: "pointer" }}
        onClick={(e) => handleCompanyDetail(e, data?.company?.id)}
      >
        <Typography variant="h2" sx={mainText}>
          <span style={{ color: `${theme.palette.primary.main}` }}>
            #{index + 1}
          </span>{" "}
          {data?.company?.name}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mt: 1.5,
          ml: 1,
          "@media (max-width: 440px)": {
            display: "flex",
            flexWrap: "wrap",
          },
        }}
      >
        <Box sx={rankBox(theme)}>
          <Button
            variant="contained"
            onClick={(e) => handleRankUp(e, data?.company?.id)}
            sx={rankButton}
            disabled={rankState?.rankUpDisable}
            startIcon={
              <Box sx={rankIconStyle}>
                <img src={ArrowUpIcon} alt="stats icon" width="100%" />
              </Box>
            }
          >
            {rankState?.rankUp}
          </Button>
          <Typography variant="subtitle" sx={rankTextStyle(theme)}>
            Rank
          </Typography>
          <Button
            variant="contained"
            onClick={(e) => handleRankDown(e, data?.company?.id)}
            sx={rankButton}
            disabled={rankState?.rankDownDisable}
            startIcon={
              <Box sx={rankIconStyle}>
                <img src={ArrowDownIcon} alt="stats icon" width="18px" />
              </Box>
            }
          >
            {rankState?.rankDown}
          </Button>
        </Box>

        <Button
          variant="contained"
          sx={commentsButton(theme, showComments)}
          onClick={handleShowComments}
        >
          Comments
        </Button>
        <Button
          onClick={handleChange}
          variant="contained"
          sx={statsButton(theme, value)}
        >
          Details
        </Button>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title="Copied"
          placement="top-start"
        >
          <Button
            onClick={(e) => handleCopy(e, data?.company?.id)}
            variant="contained"
            sx={shareButton}
            endIcon={
              <Box sx={shareIconStyle}>
                <img src={ShareIcon} alt="stats icon" width="100%" />
              </Box>
            }
          >
            Share
          </Button>
        </Tooltip>
      </Box>

      <Box sx={{ maxHeight: "700px", overflowY: "scroll" }}>
        {value && (
          <Box sx={{ padding: "20px 20px" }}>
            <Stats data={data && data?.company} />
          </Box>
        )}
        {showComments && (
          <Box sx={{ padding: "20px 20px" }}>
            <Comments id={data && data?.company?.id} />
          </Box>
        )}
      </Box>
      <CommentsModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        handleCommentCount={handleCommentCount}
      />
    </Box>
  );
};

export default Item;

import { Box } from "@mui/material";
import React from "react";
import Slider from "react-slick";

const Carousel = ({ data }) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2.5,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Box>
      {data?.portfolio_images && (
        <Slider {...settings}>
          {data?.portfolio_images?.map((item, index) => (
            <Box
              key={index}
              sx={{
                display: "flex !important",
                alignItems: "center",
                justifyContent: "center",
                background: "white",
              }}
            >
              <Box sx={{ width: "98%", height: "250px" }}>
                <img
                  src={item}
                  alt={`Slide ${index + 1}`}
                  width="100%"
                  height="100%"
                  style={{ borderRadius: "4px" }}
                />
              </Box>
            </Box>
          ))}
        </Slider>
      )}
    </Box>
  );
};

export default Carousel;

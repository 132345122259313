import * as React from "react";
import Typography from "@mui/material/Typography";
import { Box, Button, TextField, useTheme } from "@mui/material";
import Accordon from "./accordion";
import Profile from "./profile";
import PersonalList from "./listing";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { OpenLoginModal } from "store/slices/modalsSlice";
import { subscriptionSchema } from "var/Schema";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import { subscribeUser } from "api/FAQS";
import toast from "react-hot-toast";

const textfieldStyle = {
  "& ::placeholder": {
    textAlign: "left !important",
    fontWeight: `${500} !important`,
    color: `#949494 !important`,
    opacity: 1,
  },
  position: "absolute",
  bottom: "40px",
  right: "20px",
  "& .MuiOutlinedInput-root": {
    paddingRight: "0px",
    background: "#ffff",
    "&.Mui-focused": {
      "& .MuiOutlinedInput-input": {
        color: "#000",
        background: "transparent",
        borderRadius: "20px",
      },
      background: "#ffff",
      boxShadow: "0 0 0 2px #fff, 0 0 0 4px #013eb796",

      "& .MuiSvgIcon-root": {
        fill: "#fff",
      },
      "& .MuiInputAdornment-root": {
        color: "#000",
      },
    },
  },
  "& .MuiOutlinedInput-root .MuiOutlinedInput-input": {
    borderRadius: "20px",
    textAlign: "left",
  },
};

export default function Footer() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth.auth.isAuthenticate);

  // Define a mutation for change password

  const { mutate, isLoading } = useMutation(subscribeUser, {
    onSuccess: (data) => {
      resetForm();
      toast.success("You've subscribed successfully!");
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message || "Something went wrong!");
    },
  });

  // Formik object for managing form state and validation

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: subscriptionSchema,
    onSubmit: (values) => {
      const data = { subscriber: { email: values.email } };
      mutate(data);
    },
  });

  const { values, handleSubmit, handleChange, touched, errors, resetForm } =
    formik;

  const handleAddList = (e) => {
    e.preventDefault();
    if (auth) {
      navigate("/add-listing");
    } else {
      dispatch(OpenLoginModal(true));
    }
  };

  return (
    <Box
      sx={{ background: theme.palette.secondary.main, position: "relative" }}
    >
      <Box sx={{ maxWidth: "1075px", margin: "auto" }}>
        <Box
          sx={{
            padding: { xs: "30px 10px 20px 10px", sm: "50px 60px 20px 60px" },
            textAlign: "center",
          }}
        >
          <Profile />
          {auth && <PersonalList />}
          <Button
            variant="outlined"
            sx={{ maxWidth: "130px" }}
            startIcon={<Typography variant="subtitle3">+</Typography>}
            onClick={handleAddList}
          >
            Add Listing
          </Button>
          <Accordon />
          <Box>
            <TextField
              name="email"
              placeholder="   Enter email"
              value={values.email}
              // onBlur={handleBlur}
              onChange={handleChange}
              autoComplete="userData"
              error={touched.email && Boolean(errors.email)}
              helperText={
                touched.email &&
                Boolean(errors.email) &&
                touched.email &&
                errors.email
              }
              sx={textfieldStyle}
              InputProps={{
                endAdornment: (
                  <Button
                    onClick={handleSubmit}
                    variant="contained"
                    sx={{
                      maxWidth: "100px",
                      fontWeight: "400",
                      boxShadow: "none",
                      height: "39px",
                      "&:hover": {
                        boxShadow: `none`,
                      },
                    }}
                  >
                    Subscribe
                  </Button>
                ),
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

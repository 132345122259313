import { palette } from "../palette";
export const MuiTextField = {
  styleOverrides: {
    root: {
      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
        {
          display: "none",
        },
      "& input[type=number]": {
        MozAppearance: "textfield",
      },
      "& label.Mui-focused": {
        color: palette.primary.dark,
      },

      "& .MuiOutlinedInput-root": {
        backgroundColor: "#fff",

        "& fieldset": {
          borderColor: palette.primary.main,
          borderWidth: "2px",
        },
        "&:hover fieldset": {
          borderColor: palette.primary.main,
        },
        "&.Mui-focused fieldset": {
          borderColor: palette.primary.main,
          borderRadius: "20px",
          outline: "none",
        },
        "&.Mui-disabled fieldset": {
          borderColor: palette.primary.main,
        },

        "& .MuiSvgIcon-root": {
          fill: palette.primary.main,
        },

        "& .MuiOutlinedInput-input": {
          display: "flex",
          alignItems: "center",
          padding: "0px 8px  !important",
          height: "40px",
          background: "rgba(255, 255, 255, 1)",
          color: palette.primary.dark,
          overflow: "hidden",
          textOverflow: "ellipsis",
          textAlign: "center",
        },
        background: "rgba(255,255,255,0.1)",
        color: palette.primary.dark,
        fontSize: "15px",
        fontWeight: 500,
        fontFamily: "inter",
        borderRadius: "20px",
        borderWidth: "2px",

        "& .MuiInputAdornment-root": {
          color: palette.primary.main,
        },

        "&.Mui-focused": {
          "& .MuiOutlinedInput-input": {
            color: "#fff",
            background: "transparent",
            borderRadius: "20px",
          },
          background: palette.primary.main,
          boxShadow: "0 0 0 2px #fff, 0 0 0 4px #013eb796",

          "& .MuiSvgIcon-root": {
            fill: "#fff",
          },
          "& .MuiInputAdornment-root": {
            color: "#fff",
          },
          "& ::placeholder": {
            color: `${palette.primary.white} !important`,
            textAlign: "left",
            fontWeight: `${700} `,
            letterSpacing: "-0.597px",
            opacity: 0.6,
          },
        },
        "& ::placeholder": {
          color: palette.primary.main,
          textAlign: "left ",
          fontWeight: 700,
          letterSpacing: "-0.597px",
          opacity: 1,
        },
      },

      "& .MuiFormHelperText-root": {
        fontSize: "11px",
        fontWeight: 600,
        backgroundColor: "transparent",
      },
      "&  .MuiFormHelperText-root.Mui-error": {
        backgroundColor: "transparent",
        margin: 0,
        paddingLeft: 10,
      },
      input: {
        "&.Mui-disabled": {
          WebkitTextFillColor: `${palette.primary.gray} !important`,
        },
      },
    },
  },
};

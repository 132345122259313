import * as React from "react";
import {
  TextField,
  Box,
  Button,
  Typography,
  CircularProgress,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useFormik } from "formik";
import CancelIcon from "@mui/icons-material/Cancel";
import DialogActions from "@mui/material/DialogActions";
import { changePasswordSchema } from "var/Schema";
import { changePassword } from "api/Auth";
import { useMutation } from "react-query";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";

const dialogStyle = {
  "& .MuiDialog-paper": {
    width: { xs: "270px", sm: "389px" },
    maxHeight: { xs: "465px", sm: "465px", md: "465px" },
    minHeight: "360px",
    background: "#FAFAFA",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    boxRadius: "none",
    borderRadius: "8px",
  },
  "& .MuiDialog-container": {
    background: "rgba(255, 255, 255, 0.02)",
    backdropFilter: "blur(10px)",
  },
};

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const dialogHeader = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: { xs: "20px 20px 20px 20px", sm: "20px 20px 20px 40px" },
};

export default function ChangePassword({ open, setOpen }) {
  const theme = useTheme();
  const user = useSelector((state) => state.auth.auth.user);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [show, setShow] = React.useState(false);

  // Define a mutation for change password

  const { mutate, isLoading } = useMutation(changePassword, {
    onSuccess: (data) => {
      handleClose();
      toast.success("Password changed successfully");
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message || "Something went wrong!");
    },
  });

  // Formik object for managing form state and validation

  const formik = useFormik({
    initialValues: {
      password: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: changePasswordSchema,
    onSubmit: (values) => {
      const data = {
        user: {
          current_password: values.password,
          new_password: values.newPassword,
          new_password_confirmation: values.confirmPassword,
        },
      };

      mutate(data);
    },
  });

  const {
    values,
    handleBlur,
    handleSubmit,
    handleChange,
    touched,
    errors,
    resetForm,
  } = formik;

  const handleClose = () => {
    setOpen(false);
    setShow(false);
    resetForm();
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        sx={{
          "& .MuiDialog-paper": {
            width: { xs: "270px", sm: "389px" },
            maxHeight: { xs: "465px", sm: "465px", md: "465px" },
            minHeight: user?.role === "admin" ? "140px" : "360px",
            background: "#FAFAFA",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            boxRadius: "none",
            borderRadius: "8px",
          },
          "& .MuiDialog-container": {
            background: "rgba(255, 255, 255, 0.02)",
            backdropFilter: "blur(10px)",
          },
        }}
        onClose={handleClose}
      >
        <Box sx={dialogHeader}>
          <CancelIcon
            sx={{ fontSize: "20px", cursor: "pointer" }}
            onClick={handleClose}
          />
        </Box>
        {user?.role === "admin" ? (
          <Typography
            variant="subtitle2"
            sx={{
              fontSize: { xs: "13px", sm: "15px" },
              padding: "0px 16px 16px 16px",
            }}
          >
            Admin can only edit his profile details through the admin panel and
            not on the user side.
          </Typography>
        ) : (
          <>
            <Typography
              variant="h2"
              sx={{ fontSize: { xs: "13px", sm: "15px" } }}
            >
              Change your password
            </Typography>
            <form onSubmit={handleSubmit}>
              <Box
                sx={{
                  maxWidth: "300px",
                  margin: "auto",
                  padding: "30px 10px",
                  textAlign: "left",
                }}
              >
                <TextField
                  name="password"
                  type={show ? "text" : "password"}
                  placeholder="Current Password"
                  fullWidth
                  value={values.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  sx={{
                    mb: "10px",
                    "& ::placeholder": {
                      textAlign: "center !important",
                      fontWeight: `${500} !important`,
                      color: `${theme.palette.primary.gray} !important`,
                      opacity: 1,
                    },
                  }}
                  autoComplete="hello_xyz"
                  error={touched.password && Boolean(errors.password)}
                  helperText={
                    touched.password &&
                    Boolean(errors.password) &&
                    touched.password &&
                    errors.password
                  }
                />

                <TextField
                  name="newPassword"
                  type={show ? "text" : "password"}
                  placeholder="New Password"
                  fullWidth
                  value={values.newPassword}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  sx={{
                    mb: "10px",
                    "& ::placeholder": {
                      textAlign: "center !important",
                      fontWeight: `${500} !important`,
                      color: `${theme.palette.primary.gray} !important`,
                      opacity: 1,
                    },
                  }}
                  autoComplete="yzx_tel"
                  error={touched.newPassword && Boolean(errors.newPassword)}
                  helperText={
                    touched.newPassword &&
                    Boolean(errors.newPassword) &&
                    touched.newPassword &&
                    errors.newPassword
                  }
                />

                <TextField
                  name="confirmPassword"
                  type={show ? "text" : "password"}
                  placeholder="New Password"
                  fullWidth
                  value={values.confirmPassword}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  sx={{
                    mb: "10px",
                    "& ::placeholder": {
                      textAlign: "center !important",
                      fontWeight: `${500} !important`,
                      color: `${theme.palette.primary.gray} !important`,
                      opacity: 1,
                    },
                  }}
                  autoComplete="xyz-elo"
                  error={
                    touched.confirmPassword && Boolean(errors.confirmPassword)
                  }
                  helperText={
                    touched.confirmPassword &&
                    Boolean(errors.confirmPassword) &&
                    touched.confirmPassword &&
                    errors.confirmPassword
                  }
                />

                <FormControlLabel
                  value="end"
                  control={<BpCheckbox onChange={() => setShow(!show)} />}
                  label="Show passwords"
                  labelPlacement="end"
                  sx={{ ml: 0.5 }}
                />

                <DialogActions
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 0,
                    mt: 1,
                  }}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={isLoading}
                    sx={{
                      maxWidth: "500px",
                      boxShadow: "none",
                      padding: "20px 0px",
                    }}
                  >
                    {isLoading ? (
                      <CircularProgress
                        sx={{ color: theme.palette.primary.white }}
                        size={20}
                      />
                    ) : (
                      "Change Password"
                    )}
                  </Button>
                </DialogActions>
              </Box>
            </form>
          </>
        )}
      </Dialog>
    </React.Fragment>
  );
}

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#137cbd",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&::before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#106ba3",
  },
});

// Inspired by blueprintjs
function BpCheckbox(props) {
  return (
    <Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}

import { palette } from "./palette";

export const typography = {
  h1: {
    fontFamily: "inter",
    fontSize: "32px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: palette.primary.main,
    textTransform: "capitalize",
    fontWeight: 700,
  },
  h2: {
    fontFamily: "inter",
    fontSize: "22px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    fontWeight: 600,
    color: palette.primary.dark,
  },
  h3: {
    fontFamily: "inter",
    fontSize: "32px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-1.365px",
    textAlign: "center",
    fontWeight: 600,
    color: palette.primary.dark,
  },

  subtitle: {
    fontFamily: "inter",
    fontSize: "15px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.525px",
    textAlign: "center",
    color: palette.primary.dark,
  },

  subtitle1: {
    fontFamily: "inter",
    fontSize: "15px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    fontWeight: 700,
    color: palette.primary.main,
  },

  subtitle3: {
    fontFamily: "inter",
    fontSize: "28px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    textAlign: "center",
    letterSpacing: "-1.405px",
    color: palette.primary.white,
  },

  subtitle4: {
    fontFamily: "inter",
    fontSize: "16px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    textAlign: "center",
    color: palette.primary.white,
  },

  subtitle5: {
    fontFamily: "inter",
    fontSize: "15px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    textAlign: "center",
    color: palette.primary.dark,
  },

  subtitle6: {
    fontFamily: "inter",
    fontSize: "15px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.525px",
    textAlign: "center",
    opacity: 1,
    color: "#625e5e",
  },
};

import { palette } from "../palette";

export const MuiButton = {
  styleOverrides: {
    contained: {
      borderRadius: "20px",
      padding: "10px 10px",
      maxWidth: "230px",
      width: "100% ",
      minWidth: "20px",
      height: 30,
      boxShadow: `0 0 0 2px #fff, 0 0 0 4px ${palette.primary.main}`, // Double border effect
      color: "#fff",
      backgroundColor: palette.primary.main,
      fontFamily: "inter",
      fontSize: 14,
      fontStretch: "normal",
      letterSpacing: 0.36,
      fontWeight: 700,
      textTransform: "capitalize",
      "&:hover": {
        backgroundColor: palette.primary.main,
        color: "#fff",
        boxShadow: `0 0 0 2px #fff, 0 0 0 4px ${palette.primary.main}`,
        // boxShadow: `0 0 0 2px #fff, 0 0 0 4px ${palette.primary.main}`, // Double border effect
      },
      "&.Mui-disabled": {
        backgroundColor: `${palette.primary.main} !important`,
        boxShadow: `0 0 0 2px #fff, 0 0 0 4px ${palette.primary.main}`,
        color: "#fff",
        opacity: 1,
      },
    },

    outlined: {
      borderRadius: "20px",
      padding: "10px 10px",
      maxWidth: "230px",
      width: "100% ",
      minWidth: "20px",
      height: 35,
      border: `2px solid ${palette.primary.white}`,
      boxShadow: "0px 5px 11.3px 0px rgba(0, 0, 0, 0.21)",
      color: "#fff",
      backgroundColor: palette.primary.main,
      fontFamily: "inter",
      fontSize: 14,
      fontStretch: "normal",
      letterSpacing: 0.36,
      fontWeight: 700,
      textTransform: "capitalize",
      "&:hover": {
        backgroundColor: palette.primary.main,
        border: `2px solid ${palette.primary.white}`,
        boxShadow: "0px 5px 11.3px 0px rgba(0, 0, 0, 0.21)",
      },
      "&.Mui-disabled": {
        backgroundColor: `#013eb796 !important`,
        boxShadow: "0 0 0 2px #fff, 0 0 0 4px #013eb796",
        color: "#fff",
        opacity: 1,
      },
    },

    text: {
      textDecoration: "none",
      fontFamily: "inter",
      padding: "6px 0px",
      fontSize: 18,
      fontWeight: 700,
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      textAlign: "center",
      color: palette.primary.main,
      textTransform: "none",
      "&:hover": {
        textDecoration: "none",
        backgroundColor: "transparent",
      },
    },
  },
};

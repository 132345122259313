import React, { useEffect, useRef, useState } from "react";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { Avatar, Box, Typography, useTheme } from "@mui/material";
import toast from "react-hot-toast";
import { updateUser } from "api/Auth";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { userInfo } from "store/slices/authSlice";

const imageStyle = (theme) => {
  return {
    p: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    borderRadius: "50%",
    border: `1px solid  ${theme.palette.primary.main}`,
    background: "",
    height: "100%",
    width: "100%",
  };
};

const imageIcons = {
  width: "120px",
  borderRadius: "50%",
  height: "120px",
};

const ProfileAvatar = ({ handleChangePassword }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const user = useSelector((state) => state.auth.auth.user);
  const [profile, setProfile] = useState(null);
  const [hovered, setHovered] = useState(false);
  const [image, setImage] = useState(null);

  const { mutate, isLoading } = useMutation(updateUser, {
    onSuccess: (data) => {
      const { user_id, username, email, created_at, url, role, exp } =
        jwtDecode(data?.data?.token);
      const userData = {
        userId: user_id,
        userName: username,
        email,
        url,
        role,
        createdAt: created_at,
      };
      dispatch(userInfo(userData));
      toast.success("Profile image updated successfully!");
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message || "Something went wrong!");
    },
  });

  const handleHover = () => {
    setHovered(!hovered);
  };

  const handleImageUpload = async (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("user[profile_image]", file);
    mutate({ data: formData, id: user?.userId });
    setImage(URL.createObjectURL(file));
  };

  useEffect(() => {
    user && setProfile(user.url);
  }, [user]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <Box
        onMouseEnter={handleHover}
        onMouseLeave={handleHover}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          width: "120px",
          height: "120px",
          borderRadius: "50%",
        }}
      >
        <Box
          sx={imageStyle(theme)}
          onClick={
            user?.role === "admin"
              ? handleChangePassword
              : () => {
                  fileInputRef.current.click();
                }
          }
        >
          {image || profile ? (
            <img src={image || profile} style={imageIcons} alt="avatar" />
          ) : (
            <Avatar src="/broken-image.jpg" sx={imageIcons} />
          )}
        </Box>

        <input
          type="file"
          ref={fileInputRef}
          accept="image/jpeg, image/png"
          onChange={handleImageUpload}
          style={{ display: "none" }}
        />

        <IconButton
          component="label"
          htmlFor="upload-image"
          sx={{
            position: "absolute",
            bottom: "0px",
            right: 0,
            background: theme.palette.primary.main,
            height: "30px",
            width: "30px",
            ":hover": {
              background: theme.palette.primary.main,
            },
          }}
          onClick={
            user?.role === "admin"
              ? handleChangePassword
              : () => {
                  fileInputRef.current.click();
                }
          }
        >
          <PhotoCamera sx={{ fill: "#ffff", fontSize: "15px" }} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default ProfileAvatar;

import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  Logo,
  addListingIcon,
  editProfileIcon,
  logoutIcon,
} from "assets/images";
import { useDispatch, useSelector } from "react-redux";
import { OpenLoginModal } from "store/slices/modalsSlice";
import { useNavigate } from "react-router-dom";
import { userAuthenticate, userInfo } from "store/slices/authSlice";

const mainBox = {
  display: "flex",
  minHeight: "110px",
  alignItems: "center",
  justifyContent: "space-between",
  padding: { xs: "0px 10px", sm: "10px 25px 25px 25px" },
};

const logoBox = {
  minHeight: { xs: "27px", sm: "32px", md: "35px" },
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
};

const logoImageBox = { width: { xs: "17px", sm: "25px", md: "35px" } };

const dividerStyle = (theme) => {
  return { borderColor: "#000", margin: "0px 8px" };
};

const logoText = { fontSize: { xs: "17px", sm: "30px", md: "40px" } };

const buttonBox = { display: "flex", flexDirection: "column" };

const buttonStyle = (theme) => {
  return {
    boxShadow: `0 0 0 2px #fff, 0 0 0 4px ${theme.palette.primary.dark}`,
    background: theme.palette.primary.dark,
    height: { xs: "20px", sm: "22", md: "25px" },
    fontSize: { xs: "8px", sm: "10px", md: "12px" },
    letterSpacing: "-0.42px",
  };
};

const iconMainBox = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
};

const iconButtonBox = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  cursor: "pointer",
  justifyContent: "center",
};

const iconStyle = { width: { xs: "20px", sm: "30px" } };

const iconText = { fontSize: { xs: "6px", sm: "9px" } };

const profileBox = {
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "center",
  padding: "8px",
  flexDirection: "column",
  minWidth: { xs: "105px", sm: "180px" },
  maxWidth: { xs: "105px", sm: "180px" },
  width: "100%",
};

const avatarStyle = (theme) => {
  return {
    width: { xs: "20px", sm: "30px", md: "30px" },
    height: { xs: "20px", sm: "30px", md: "30px" },
    border: `1px solid ${theme.palette.primary.main}`,
  };
};

const textStyle = {
  fontWeight: 500,
  fontSize: { xs: "9px", sm: "12px", md: "16px" },
  textAlign: "left",
  width: { xs: "75px", sm: "145px" },
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const Navbar = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth.auth.isAuthenticate);
  const user = useSelector((state) => state.auth.auth.user);
  const [profile, setProfile] = useState(null);

  const handleClick = (e) => {
    e.preventDefault();
    dispatch(OpenLoginModal(true));
  };

  const handleAddList = (e) => {
    e.preventDefault();
    navigate("/add-listing");
    window.location.reload(true);
  };

  const handleProfile = (e) => {
    e.preventDefault();
    navigate("/profile");
  };

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(userAuthenticate(false));
    dispatch(userInfo(null));
    localStorage.removeItem("token");
  };

  useEffect(() => {
    user && setProfile(user.url);
  }, [user]);

  return (
    <Box sx={mainBox}>
      <Box sx={logoBox} onClick={() => navigate("/")}>
        <Box sx={logoImageBox}>
          <img src={Logo} alt="logo" width="100%" />
        </Box>
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={dividerStyle(theme)}
        />
        <Typography variant="h2" sx={logoText}>
          Deal Ranker
        </Typography>
      </Box>

      <Box sx={buttonBox}>
        {!auth ? (
          <Button
            variant="contained"
            sx={buttonStyle(theme)}
            onClick={handleClick}
          >
            Login / Create account
          </Button>
        ) : (
          <Box sx={profileBox}>
            <Box>
              <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
                <Avatar
                  src={profile || "/broken-image.jpg"}
                  sx={avatarStyle(theme)}
                />

                <Typography variant="subtitle" sx={textStyle}>
                  {user?.userName}
                </Typography>
              </Stack>
            </Box>

            <Divider flexItem sx={{ m: "8px 0px" }} />

            <Box sx={iconMainBox}>
              <Box sx={iconButtonBox} onClick={handleProfile}>
                <Box sx={iconStyle}>
                  <img src={editProfileIcon} width="100%" />
                </Box>
                <Typography sx={iconText}>Edit Profile</Typography>
              </Box>
              <Box sx={iconButtonBox} onClick={handleAddList}>
                <Box sx={iconStyle}>
                  <img src={addListingIcon} width="100%" />
                </Box>
                <Typography sx={iconText}>Add Listing</Typography>
              </Box>
              <Box sx={iconButtonBox} onClick={handleLogout}>
                <Box sx={iconStyle}>
                  <img src={logoutIcon} width="100%" />
                </Box>
                <Typography sx={iconText}>Logout</Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Navbar;

import React from "react";
import { connect } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = ({ isAuthenticate }) => {
  return isAuthenticate ? <Outlet /> : <Navigate to="/" />;
};

const mapStateToProps = (state) => ({
  isAuthenticate: state.auth.auth.isAuthenticate,
});

export default connect(mapStateToProps)(PrivateRoute);

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticate: false,
  user:null
};

export const authSlice = createSlice({
  name: "authenticate",
  initialState,
  reducers: {
    userAuthenticate: (state, action) => {
      state.isAuthenticate = action.payload;
    },
    userInfo: (state, action) => {
        state.user = action.payload;
  
      },
  },
});

// Action creators are generated for each case reducer function
export const { userAuthenticate,userInfo } =
  authSlice.actions;

export default authSlice.reducer;
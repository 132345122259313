import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import Item from "./components/Item";
import Filter from "./components/Filter";
import { useQuery } from "react-query";
import { getAllListing } from "api/Listing";
import Loader from "components/loader";
import { useTheme } from "@emotion/react";
import NoData from "components/nodata";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const Listing = () => {
  const theme = useTheme();
  const param = useParams();
  const auth = useSelector((state) => state.auth.auth.isAuthenticate);
  const id = param?.id;
  const [filter, setFilter] = useState({
    query: "",
    industry: "",
    state: "",
    city: "",
    owner: "",
    id: id,
    page: 1,
    per_page: 10,
  });

  // Fetch listing data
  const { data, isLoading } = useQuery({
    queryKey: ["getAllListing", filter],
    // Specifies the function that fetches the data for the query
    queryFn: () => getAllListing(filter),
    retry: false,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    notifyOnChangeProps: ["data", "error"],
  });

  const handleSeeMore = () => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      per_page: prevFilter.per_page + 10,
    }));
  };

  useEffect(() => {
    if (param?.id === undefined) {
      setFilter({ ...filter, id: "" });
    }
  }, [param]);
  return (
    <Box
      sx={{
        padding: { xs: "15px 10px", sm: "25px 60px" },
        textAlign: "center",
      }}
    >
      <Typography
        variant="h1"
        sx={{
          fontSize: { xs: "22px", sm: "26px", md: "30px" },
          fontWeight: 600,
          color: theme.palette.primary.dark,
        }}
      >
        Find, Rank, and Discuss
      </Typography>
      <Typography
        variant="h1"
        sx={{
          fontSize: { xs: "22px", sm: "26px", md: "30px" },
          fontWeight: 600,
          color: theme.palette.primary.dark,
        }}
      >
        Business Listings and Ideas
      </Typography>

      <Box sx={{ margin: "auto", maxWidth: "650px" }}>
        <Filter setFilter={setFilter} filter={filter} />
      </Box>
      <Typography
        variant="h1"
        sx={{
          fontSize: { xs: "22px", sm: "28px", md: "32px" },
          mb: 4,
          mt: 4,
        }}
      >
        Top Ranked Listings
      </Typography>
      {isLoading ? (
        <Box
          sx={{
            border: `2px solid ${theme.palette.primary.main}`,
            borderRadius: "8px",
          }}
        >
          <Loader height={400} />
        </Box>
      ) : (!isLoading && !data?.companies_data) ||
        !data?.companies_data?.length ? (
        <Box
          sx={{
            border: `2px solid ${theme.palette.primary.main}`,
            borderRadius: "8px",
          }}
        >
          <NoData height={400} />
        </Box>
      ) : (
        data?.companies_data &&
        data?.companies_data?.map((value, index) => (
          <Box key={index} sx={{ mb: 1, mt: 4 }}>
            <Item data={value} index={index} />
          </Box>
        ))
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {!id && data?.companies_count > 10 && (
          <Button
            variant="text"
            sx={{ maxWidth: "130px", mt: 2, textDecoration: "underline" }}
            onClick={handleSeeMore}
          >
            See more
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default Listing;

import React from "react";
import Navbar from "./navbar";
import { Box } from "@mui/material";
import SignIn from "components/modals/SignIn";
import Footer from "./footer";

const MainLayout = ({ children }) => {
  return (
    <React.Fragment>
      <Box sx={{ maxWidth: "1100px", margin: "auto" }}>
        <Navbar />
        <Box>{children}</Box>
      </Box>
      <Footer />
      <SignIn />
    </React.Fragment>
  );
};

export default MainLayout;
